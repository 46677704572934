import React, {createContext, useContext, useEffect, useState} from 'react';
import {StockSearchResponse} from "./StockSearchResponse";
import {AuthenticatedUserContext} from "./AuthenticatedUserProvider";
import {ChallengeService} from "../screens/challenge/services/ChallengeService";
import {logException} from "../utils/LogException";
import {Unsubscribe} from "firebase/firestore"
import {ChallengeUserType} from "../screens/challenge/model/ChallengeUserType";
import {ChallengeType} from "../screens/challenge/model/ChallengeType";

export const ChallengeContext = createContext<{
    challengeId: string,
    challengeList: ChallengeType[],
    setChallengeId(id?: string),
    selectedChallenge: ChallengeType,
    setSelectedChallenge(challenge?: ChallengeType),
    selectedStock: StockSearchResponse,
    challengeUser: ChallengeUserType,
    setChallengeUser(challengeUser: ChallengeUserType),
    updateUserStock(selectedStock: StockSearchResponse)
    updateUserConfirmed()
    challengeConfirmed: boolean,
    messages: any[]
}>({} as any);


export const ChallengeProvider = ({children}) => {
    const {user} = useContext(AuthenticatedUserContext);
    const [selectedStock, setSelectedStock] = React.useState<StockSearchResponse>();
    const [challengeUser, setChallengeUser] = React.useState<ChallengeUserType>();
    const [challengeId, setChallengeId] = React.useState<string>();
    const [selectedChallenge, setSelectedChallenge] = React.useState<ChallengeType>();
    const [challengeList, setChallengeList] = React.useState<ChallengeType[]>([]);
    const [challengeConfirmed, setChallengeConfirmed] = React.useState<boolean>(false);
    const [messages, setMessages] = useState([]);

    let unsubscribeChallengeSnapshot: Unsubscribe = undefined;
    let unsubscribeMessagesSnapshot: Unsubscribe = undefined;

    useEffect(() => {
        if (!challengeId) {
            return new ChallengeService().snapshotChallenges(setChallengeList);
        }
    }, [])

    useEffect(() => {
        const updateChallengeUser = async () => {
            try {
                let fetchedChallengeUser = await new ChallengeService().fetchChallengeUser(selectedChallenge.id, user.uid);
                setChallengeConfirmed(!!fetchedChallengeUser.hasConfirmed)
                setChallengeUser(fetchedChallengeUser);
                setSelectedStock(fetchedChallengeUser.selectedStock);
            } catch (e) {
                logException(e);
            }
        }
        if (selectedChallenge?.id === undefined || challengeId !== selectedChallenge?.id) {
            setSelectedStock(undefined);
            setChallengeUser(undefined);
        }
        if (selectedChallenge?.id) {
            updateChallengeUser();
        }
    }, [selectedChallenge])

    useEffect(() => {
        if (unsubscribeChallengeSnapshot) {
            unsubscribeChallengeSnapshot();
        }
        if ((!challengeId || challengeId !== selectedChallenge?.id) && selectedChallenge) {
            setSelectedChallenge(undefined);
        }
        if (challengeId) {
            unsubscribeChallengeSnapshot = new ChallengeService().snapshotSelectedChallenge(challengeId, setSelectedChallenge)
            return unsubscribeChallengeSnapshot;
        }
    }, [challengeId])

    useEffect(() => {
        if (unsubscribeMessagesSnapshot) {
            unsubscribeMessagesSnapshot();
        }
        if (!challengeId) {
            setMessages([]);
        }
        if (challengeId) {
            unsubscribeMessagesSnapshot = new ChallengeService().snapshotChallengeChatMessages(challengeId, (messages) => {
                setMessages(messages
                    .map((message) => ({...message, createdAt: message.createdAt.toDate()}))
                    .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
                )
            })
            return unsubscribeMessagesSnapshot;
        }
    }, [challengeId])

    async function updateUserStock(selectedStock: StockSearchResponse) {
        setSelectedStock(selectedStock);
        try {
            await new ChallengeService().updateUserStock(challengeId, user, selectedStock);
        } catch (e) {
            logException(e);
        }
    }

    async function updateUserConfirmed() {
        setChallengeConfirmed(true);
        try {
            await new ChallengeService().updateUserConfirmed(challengeId, user);
        } catch (e) {
            logException(e);
        }
    }

    return (
        <ChallengeContext.Provider value={{
            challengeId,
            setChallengeId,
            selectedChallenge,
            setSelectedChallenge,
            selectedStock,
            challengeUser,
            setChallengeUser,
            updateUserStock,
            updateUserConfirmed,
            challengeList,
            challengeConfirmed,
            messages
        }}>
            {children}
        </ChallengeContext.Provider>
    );
};
