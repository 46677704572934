import React, {useContext, useEffect} from "react";
import {ChallengeContext} from "../../../providers";
import {Linking, StyleSheet, Text, View} from "react-native";
import {auth, Colors} from "../../../config";
import {StockPriceResponse} from "../../../providers/StockPriceResponse";
import {currency2string} from "../../../utils/Constants";
import {LSOViewWidget} from "../../../components/LSOViewWidget";
import {StockService} from "../services/StockService";
import {Button, Dialog} from "@rneui/base";
import {logException} from "../../../utils/LogException";
import {ChallengeService} from "../services/ChallengeService";
import moment from "moment";
import {time2string} from "../../../utils/Utils";
import {exchangeList} from "../../../utils/ExchangeList";

export const SelectedStockDisplayComponent = () => {

    const {
        selectedStock,
        updateUserStock,
        selectedChallenge,
        challengeUser,
        challengeId,
        setChallengeUser
    } = useContext(ChallengeContext);
    const [stockPrice, setStockPrice] = React.useState<StockPriceResponse>();
    const [dialogChangeStockVisible, setDialogChangeStockVisible] = React.useState(false);
    const [userConfirmedStock, setUserConfirmedStock] = React.useState(false);

    if (!selectedStock) {
        return <></>
    }

    useEffect(() => {
        if (challengeUser) {
            setUserConfirmedStock(challengeUser.hasConfirmedStock);
        }
    }, [challengeUser, challengeUser?.hasConfirmedStock]);

    useEffect(() => {
        async function updateStockPrice() {
            try {
                setStockPrice(await new StockService().fetchStockPrice(selectedStock.symbol));
            } catch (e) {
                logException(e);
            }
        }

        updateStockPrice()
    }, [selectedStock])

    return <>
        <LSOViewWidget wideStyle text={userConfirmedStock ? undefined : <Button
            title="Auswahl bestätigen"
            color={Colors.button}
            titleStyle={{
                color: Colors.mediumGray,
                fontFamily: "Montserrat_700Bold"
            }}
            onPress={() => {
                challengeUser.hasConfirmedStock = true;
                setChallengeUser(challengeUser);
                setUserConfirmedStock(true)
                new ChallengeService().updateUser(challengeId, auth.currentUser.uid, {hasConfirmedStock: true});
            }}
        />}>
            <Text style={styles.headerText}>Ausgewähltes Wertpapier:</Text>
            <View style={styles.stockNameContainer}>
                <Text style={styles.stockNameText}>{selectedStock.Name}</Text>
            </View>
            <View style={styles.stockDetailContainer}>
                <Text style={styles.stockDetailText}>ISIN: {selectedStock.ISIN}</Text>
                <Text style={styles.stockDetailText}>Land: {selectedStock.Country}</Text>
                <Text style={styles.stockDetailText}>Währung: {selectedStock.Currency}</Text>
                <Text
                    style={styles.stockDetailText}>Börse: {exchangeList.find(exchange => exchange.Code === selectedStock.Exchange)?.Name || selectedStock.Exchange}</Text>
                <Text style={styles.stockDetailText}>aktueller Kurs:&nbsp;
                    {currency2string(parseFloat(stockPrice?.stockPrice) || 0, selectedStock.Currency)}
                </Text>
                {stockPrice && stockPrice?.timestamp.toString() !== "NA" && <Text
                    style={styles.stockDetailText}>Kursalter: {time2string(moment().diff(new Date(stockPrice.timestamp * 1000), "minutes"))}</Text>}

                <Button
                    onPress={() => Linking.openURL("https://aktie.traderfox.com/visualizations/" + (selectedStock.ISIN !== undefined ? selectedStock.ISIN : selectedStock.symbol.split(".")[0]))}
                    buttonStyle={{backgroundColor: Colors.mediumGray, padding: 0, margin: 0}}
                    titleStyle={{color: Colors.white, fontSize: 12, padding: 0, margin: 0}}
                >
                    mehr auf Traderfox
                </Button>
            </View>

        </LSOViewWidget>

        {!(Date.parse(selectedChallenge?.start).toFixed() < Date.now().toFixed()) &&
            <Button
                style={{
                    marginHorizontal: 10,
                    marginVertical: 10,
                    borderColor: Colors.white,
                    borderWidth: 1,
                }}
                color={Colors.black}
                title="Vielleicht überlege ich es mir nochmal"
                onPress={() => {
                    setDialogChangeStockVisible(true);
                }}
            />}

        <Dialog
            isVisible={dialogChangeStockVisible}
            onBackdropPress={() => setDialogChangeStockVisible(false)}
        >
            <Dialog.Title titleStyle={{color: Colors.white}} title="Achtung"/>
            <Text style={{color: Colors.white}}>Willst du das Wertpapier wirklich zurücksetzen?</Text>
            <Dialog.Actions>
                <Dialog.Button title="Wertpapier ändern" onPress={() => {
                    updateUserStock(null);
                    new ChallengeService().updateUser(challengeId, auth.currentUser.uid, {hasConfirmedStock: false});
                    challengeUser.hasConfirmedStock = false;
                    setChallengeUser(challengeUser);
                    setUserConfirmedStock(false)
                    setDialogChangeStockVisible(false)
                }}/>
                <Dialog.Button title="Abbrechen" onPress={() => setDialogChangeStockVisible(false)}/>
            </Dialog.Actions>
        </Dialog>
    </>
}

const styles = StyleSheet.create({
    headerText: {
        color: Colors.white,
        fontSize: 12,
        fontWeight: "bold",
        marginTop: 10
    },
    stockNameText: {
        color: Colors.white,
        fontSize: 12,
        fontWeight: "bold"
    },
    stockNameContainer: {
        marginTop: 10,
        width: "100%",
        borderBottomWidth: 1,
        borderColor: Colors.white,
        padding: 5
    },
    stockDetailContainer: {
        alignItems: "flex-start",
        width: "100%",
        marginTop: 30,
        marginBottom: 30
    },
    stockDetailText: {
        color: Colors.white,
        fontSize: 12,
        fontWeight: "bold"
    },
});