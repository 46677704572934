import React, {useContext, useEffect} from "react";
import {Colors} from "../../../config";
import {StyleSheet, Text, View} from "react-native";
import {ChallengeContext} from "../../../providers";
import {StockSearchResponse} from "../../../providers/StockSearchResponse";
import {LSOViewWidget} from "../../../components/LSOViewWidget";
import {TextInput} from "../../../components";
import {Button, Dialog, LinearProgress, ListItem} from "@rneui/base";
import {StockService} from "../services/StockService";
import {logException} from "../../../utils/LogException";
import {getExchangeName, removeUndefinedProperties} from "../utils/Utils";
import {useDebounce} from "../hooks/useDebounce";


export const StockSearchInput = () => {

    const [stockSearchText, onChangeStockSearchText] = React.useState<string>("");
    const [data, setData] = React.useState<StockSearchResponse[]>([]);
    const {updateUserStock, selectedChallenge} = useContext(ChallengeContext);
    const [showSpinner, setShowSpinner] = React.useState(false);
    const debouncedSearchTerm = useDebounce(stockSearchText, 500);

    const [aiName, setAiName] = React.useState("");
    const [aiSymbol, setAiSymbol] = React.useState("");
    const [aiReason, setAiReason] = React.useState("");
    const [showAiSpinner, setShowAiSpinner] = React.useState(false);
    const [aiProgress, setAiProgress] = React.useState(0);


    const [aiHelpDialog, setAiHelpDialog] = React.useState<boolean>(false);

    useEffect(() => {
        if (aiProgress < 1 && showAiSpinner) {
            const timer = setTimeout(() => {
                if (aiProgress < 1) {
                    setAiProgress(aiProgress + 0.1);
                }
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [aiProgress, showAiSpinner]);

    useEffect(() => {

        async function searchStock(search: string) {
            let stockList: StockSearchResponse[] = [];
            try {
                stockList = await new StockService().searchForStocks2(search);
            } catch (e) {
                try {
                    stockList = await new StockService().searchForStocks(search);
                } catch (e) {
                    logException(e)
                }
            }

            console.log("response from stock search endpoint", stockList);
            setData(stockList)
        }

        if (stockSearchText.length >= 3) {
            console.log('searching for : ', stockSearchText);
            searchStock(debouncedSearchTerm);
        }

    }, [debouncedSearchTerm]);

    useEffect(() => {
        if (selectedChallenge.stockList && selectedChallenge.stockList.length >= 1) {
            setData(selectedChallenge.stockList)
        }
    }, [selectedChallenge.stockList])

    return (<>
            <LSOViewWidget wideStyle>
                <View style={{flexDirection: "row"}}>
                    <Text style={styles.selectText}>choose wisely!</Text>
                    <Button title={"KI Hilfe"} buttonStyle={{
                        backgroundColor: Colors.mediumGray,
                        borderRadius: 20,
                        marginLeft: 20,
                        marginTop: 5
                    }} titleStyle={{fontSize: 15}} size="sm" onPress={() => {
                        setAiHelpDialog(true)
                    }}/>
                </View>

                {(!selectedChallenge.stockList || selectedChallenge.stockList.length === 0) && <TextInput
                    placeholder='Wertpapier suchen'
                    value={stockSearchText}
                    onChangeText={onChangeStockSearchText} leftIconName={undefined} rightIcon={undefined}
                    handlePasswordVisibility={undefined}/>}
                {showSpinner && <LinearProgress style={{marginVertical: 10}}/>}
                <View style={{width: "100%"}}>
                    {
                        data.map((stockResponse, i) => (
                            <ListItem key={i} bottomDivider containerStyle={styles.listItemContentContainer}
                                      onPress={async () => {
                                          setShowSpinner(true);
                                          try {
                                              const stockPriceResponse = await new StockService().fetchStockPrice(stockResponse.symbol)
                                              updateUserStock(removeUndefinedProperties({
                                                  ...stockResponse,
                                                  previousClose: stockPriceResponse.stockPrice,
                                                  previousCloseDate: new Date().toISOString()
                                              }) as StockSearchResponse)
                                          } catch (e) {
                                              logException(e);
                                          }
                                          setShowSpinner(false);

                                      }}
                            >
                                <ListItem.Content>
                                    <ListItem.Title style={{color: Colors.white}}>{stockResponse.Name}</ListItem.Title>
                                    <ListItem.Subtitle
                                        style={{color: Colors.white}}>{stockResponse.ISIN} - {stockResponse.Type} - {stockResponse.Currency} - {getExchangeName(stockResponse.Exchange)}</ListItem.Subtitle>
                                </ListItem.Content>
                            </ListItem>
                        ))
                    }
                </View>
            </LSOViewWidget>

            <Dialog
                isVisible={aiHelpDialog}
                onShow={async () => {
                    setShowAiSpinner(true);
                    setAiProgress(0);
                    new StockService().getAiHint().then((response) => {
                        setAiName(response.name);
                        setAiSymbol(response.isin);
                        setAiReason(response.reason);
                        setShowAiSpinner(false);
                    }).catch((e) => {
                        logException(e);
                        setAiName("Fehler!!");
                        setAiSymbol("");
                        setAiReason("Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.");
                        setShowAiSpinner(false);
                    });
                }}
                onBackdropPress={() => {
                    setAiHelpDialog(false)
                }}
            >
                {!showAiSpinner && <Dialog.Title titleStyle={{color: Colors.white}} title={`Vorschlag: ${aiName}`}/>}
                {showAiSpinner &&
                    <Dialog.Title titleStyle={{color: Colors.white}} title={`Vorschlag wird generiert!`}/>}

                {showAiSpinner &&
                    <LinearProgress style={{marginVertical: 10}} value={aiProgress < 1 ? aiProgress : undefined}/>}

                {showAiSpinner &&
                    <Text style={{color: Colors.white}}>Das kann ungefähr eine halbe Minute dauern!</Text>}
                {!showAiSpinner && <Text style={{color: Colors.white}}>{aiReason}</Text>}

                <Dialog.Actions>
                    <Dialog.Button title="abbrechen" onPress={() => {
                        setAiHelpDialog(false)
                    }}/>
                    {!showAiSpinner && aiSymbol.trim() !== "" && <Dialog.Button title="übernehmen" onPress={() => {
                        setAiHelpDialog(false)
                        stockSearchText !== aiSymbol && onChangeStockSearchText(aiSymbol);
                    }}/>}
                </Dialog.Actions>
            </Dialog>
        </>
    );
}

const styles = StyleSheet.create({
    listItemContentContainer: {
        padding: 5,
        backgroundColor: Colors.blackGrey,
        borderBottomWidth: 1,
        borderColor: Colors.mediumGray
    },
    selectText: {
        fontSize: 24,
        marginTop: 20,
        fontWeight: "bold",
        color: Colors.white
    },
    itemText: {
        fontSize: 15,
        margin: 2,
        color: Colors.white
    },
    autoComplete: {
        backgroundColor: Colors.white
    }
});