import React, {useContext} from "react";
import {ChallengeContext} from "../../../providers";
import {ChallengeTimeComponent} from "./ChallengeTimeComponent";
import {ChallengeRankingComponent} from "./ChallengeRankingComponent";


export const ChallengeActiveComponent = () => {
    const {selectedChallenge} = useContext(ChallengeContext);
    return <>
        <ChallengeTimeComponent text={"Challenge endet in:"} date={selectedChallenge.end}/>
        <ChallengeRankingComponent/>
    </>;
}