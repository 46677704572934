import React, {useEffect} from "react";
import {UserService} from "../../../providers/UserService";
import {HasUserInfo} from "../model/HasUserInfo";

const userCache = new Map<string, string>();
const userEmailCache = new Map<string, string>();

export const UserNameComponent = (props: { user: HasUserInfo }) => {

    const [userName, setUserName] = React.useState<string>(
        userCache.get(props.user.id) ||
        userEmailCache.get(props.user.email) ||
        props.user.displayName ||
        props.user.email);

    useEffect(() => {
        // if (!userCache.get(props.user.id) && !userEmailCache.get(props.user.email))
        {
            if (props.user.id) {
                new UserService().getUsername(props.user.id).then(value => {
                    if (value) {
                        setUserName(value)
                        userCache.set(props.user.id, value);
                        return
                    }
                }).catch(_ => {
                });
            }
            if (props.user.email) {
                new UserService().getUsernameByEmail(props.user.email).then(value => {
                    if (value) {
                        setUserName(value)
                        userEmailCache.set(props.user.email, value);
                    }
                }).catch(_ => {
                });
            }
        }
    }, []);

    return <>{userName}</>;
}