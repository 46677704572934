import {collection, doc, getDoc, getDocs, query, setDoc, where} from "firebase/firestore";
import {db} from "../config";

export class UserService {

    public static FB_USER_ROOT = "los-userInformation";

    public async updateUsername(userId: string, userName: string, email: string) {
        const userDocRef = await getDoc(this.getUserDoc(userId));

        try {
            await setDoc(userDocRef.ref, {
                userName, email
            });
        } catch (e) {
            console.log(e)
        }
    }

    public async getUsername(userId: string) {
        const userDocRef = await getDoc(this.getUserDoc(userId));

        try {
            if (userDocRef.exists()) {
                return await userDocRef.data().userName as string;
            }
        } catch (e) {
            console.log(e)
        }
        return undefined;
    }

    public async getUsernameByEmail(email: string) {
        const collectionReference = await collection(db, UserService.FB_USER_ROOT);
        const q = query(collectionReference, where("email", "==", email));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            return querySnapshot.docs[0].data().userName;
        }
        return undefined;
    }

    private getUserDoc(userId: string) {
        return doc(db, UserService.FB_USER_ROOT, userId);
    }
}