import moment from "moment/moment";
import {StyleSheet, Text, View} from "react-native";
import React from "react";
import {Colors} from "../../../config";


export const ChallengeTimeComponent = (props: { text: string, date: string }) => {
    let endDate = moment(props.date);
    const daysDiff = endDate.diff(moment(), "days");

    endDate = endDate.subtract(daysDiff, "days");
    const hoursDiff = endDate.diff(moment(), "hours");

    endDate = endDate.subtract(hoursDiff, "hours");
    const minutesDiff = endDate.diff(moment(), "minutes");

    return <>
        <View style={styles.challengeEndContainer}>
            <Text style={styles.challengeTimeTitle}>{props.text}</Text>
            <View style={styles.challengeTimeView}>
                <Text style={styles.challengeTimeText}>
                    {daysDiff} D
                </Text>
                <Text style={styles.challengeTimeText}>
                    {hoursDiff} H
                </Text>
                <Text style={styles.challengeTimeText}>
                    {minutesDiff} M
                </Text>
            </View>
        </View>
    </>
}

const styles = StyleSheet.create({
    challengeEndContainer: {
        marginHorizontal: 20,
        alignItems: "center",
        flexDirection: "column",
    },
    challengeTimeView: {
        marginTop: 5,
        marginBottom: 20,
        paddingVertical: 10,
        backgroundColor: "#343f46",
        borderColor: Colors.mediumGray,
        borderWidth: 1,
        flexDirection: "row",
        width: "100%",
        justifyContent: 'space-between',
    },
    challengeTimeText: {
        fontSize: 24,
        fontWeight: "bold",
        color: Colors.white,
        paddingHorizontal: 25,
        textAlign: "center",
    },
    challengeTimeTitle: {
        fontSize: 18,
        fontWeight: "bold",
        color: Colors.white,
        marginTop: 20,
    },
});