import {LSOViewWidget} from "../../../components/LSOViewWidget";
import {Dimensions, Linking, StyleSheet, Text, View} from "react-native";
import React, {useContext, useEffect} from "react";
import {Colors} from "../../../config";
import {AuthenticatedUserContext, ChallengeContext} from "../../../providers";
import {Button, ListItem} from "@rneui/base";
import {currency2string, percent2string} from "../../../utils/Constants";
import moment from "moment";
import {TouchableOpacity} from "react-native-gesture-handler";
import {exchangeList} from "../../../utils/ExchangeList";
import {time2string} from "../../../utils/Utils";
import {UserNameComponent} from "./UserNameComponent";
import {rankedList} from "../utils/Utils";
import {LineChart} from "react-native-chart-kit";
import {Dataset} from "react-native-chart-kit/dist/HelperTypes";
import {HistoricalStockPriceResponse, MOMENT_BACKEND_DATE_FORMAT, StockService} from "../services/StockService";
import {ChallengeType} from "../model/ChallengeType";

export const ChallengeRankingChart = () => {

    const {selectedChallenge} = useContext(ChallengeContext);
    const {user} = useContext(AuthenticatedUserContext);
    const [data, setData] = React.useState<Dataset[]>([]);

    const startDate = moment(selectedChallenge.start);
    const endDate = selectedChallenge.isFinished ? moment(selectedChallenge.end) : moment();
    const numberOfDays = Math.abs(startDate.diff(endDate, "days")) + 1;

    useEffect(() => {
        if (selectedChallenge.currentUserData && selectedChallenge.currentUserData.length > 0) {
            getStockPriceList().then(console.log);
        }
    }, [selectedChallenge.currentUserData])

    useEffect(() => {
        if (selectedChallenge.currentUserData) {
            setData(selectedChallenge.currentUserData.map(cud => {
                return {
                    data: new Array(numberOfDays + 1).fill(cud.performance || 0)
                }
            }));
        }
    }, [selectedChallenge.currentUserData])

    if (!data || data.length === 0) {
        return <></>
    }

    async function getStockPriceList() {
        const historicalPrices = await requestHistoricalPrices(selectedChallenge)
        const challengePrices = selectedChallenge.currentUserData.map(cud => {
            const prices = historicalPrices.find(hp => hp.symbol === cud.symbol)?.prices;
            return {challengeUserData: cud, prices: prices}
        });

        let start = moment(selectedChallenge.start);
        let end = moment(selectedChallenge.isFinished ? selectedChallenge.end : undefined);
        if (!start.isAfter(end)) {
            const dateList = [start.format(MOMENT_BACKEND_DATE_FORMAT)];
            while (start.isBefore(end)) {
                start = start.add(1, "days");
                dateList.push(start.format(MOMENT_BACKEND_DATE_FORMAT));
            }
            const challengeData = challengePrices.map(cp => {
                    const prices = cp.prices;
                    if (prices && prices.length >= 1) {
                        const fullPriceList = dateList.map(date => {
                                let price = prices[0].open
                                for (const currentPrice of prices) {
                                    if (currentPrice.date === date) {
                                        price = currentPrice.open;
                                        break;
                                    } else if (moment(currentPrice.date).isAfter(date)) {
                                        break;
                                    } else {
                                        price = currentPrice.close;
                                    }
                                }
                                return parseFloat(cp.challengeUserData.price) / parseFloat(price) * 100 - 100;
                            }
                        );
                        return {challengeUserData: cp.challengeUserData, fullPriceList}
                    }
                    return {challengeUserData: cp.challengeUserData, fullPriceList: []}
                }
            );
            const priceData = challengeData
                .filter(cd => cd.fullPriceList.length > 0);
            setData(priceData.map(pd => {
                return {
                    data: [0, ...pd.fullPriceList.map(price => selectedChallenge.mode?.negativeWins ? price * -1 : price).reverse()],
                    color: (opacity = 1) => pd.challengeUserData.email === user.email ? `rgba(255, 0, 0, 0.8)` : `rgba(255, 255, 255, 0.3)`,

                }
            }));
        }

    }

    async function requestHistoricalPrices(challenge: ChallengeType): Promise<{
        symbol: string,
        prices: HistoricalStockPriceResponse[]
    }[]> {
        const uniqueSymbolList = [...new Set(challenge.challengeUserData.map(userData => userData.symbol))];
        return Promise.all(
            uniqueSymbolList
                .map(async symbol => {
                    // get the start and end dates from the challenge data
                    let start = moment(challenge.start).format(MOMENT_BACKEND_DATE_FORMAT);
                    let end = moment(challenge.end).format(MOMENT_BACKEND_DATE_FORMAT);
                    // call the historicalPrices function with the symbol, start, end and period parameters and return the result
                    let replaceSymbol = symbol.replace(".PINK", ".US").replace(".NASDAQ", ".US").replace(".NYSE", ".US");
                    return {
                        symbol: symbol,
                        prices: await new StockService().historicalPrices(replaceSymbol, start, end, "d").catch(_ => [])
                    };
                }));
    }

    return <LineChart
        data={{
            // labels: numberOfDays > 8 ? [] : [...Array(numberOfDays).keys()].map(i => moment(selectedChallenge.start).add(i, "days").format("DD.MM.")),
            labels: [],
            datasets: data
        }}
        width={Dimensions.get("window").width - 60} // from react-native
        height={220}
        chartConfig={{
            decimalPlaces: 2, // optional, defaults to 2dp
            color: (opacity = 1) => `rgba(255, 255, 255, 0.2)`,
            labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
            propsForDots: {
                r: "1",
                strokeWidth: "2",
                stroke: `rgba(255, 255, 255, 0)`
            }
        }}
        bezier
        style={{
            backgroundColor: "#000",
            marginVertical: 8,
            borderRadius: 16
        }}
    />
}

export const ChallengeRankingComponent = () => {

    const {selectedChallenge} = useContext(ChallengeContext);
    const [visible, setVisible] = React.useState<number[]>([]);

    return <>
        <LSOViewWidget wideStyle>
            <Text style={styles.challengeText}>aktuelles Ranking </Text>
            <Text style={{
                fontSize: 12,
                fontWeight: "normal",
                color: Colors.white
            }}>zuletzt
                aktualisiert: {time2string(moment().diff(selectedChallenge.currentUserDataTimeStamp, "minutes"), "vor ")}</Text>

            <ChallengeRankingChart/>

            <View style={{width: "100%"}}>
                <>
                    {selectedChallenge?.currentUserData && selectedChallenge.currentUserData.length >= 1 &&
                        rankedList(selectedChallenge.currentUserData, !!selectedChallenge.mode?.negativeWins)
                            .map(({user, rank}, i) => (
                                <ListItem key={i} bottomDivider containerStyle={styles.listItemContentContainer}>
                                    <Text style={rank < 3 ? styles.numberPodestText : styles.numberText}>{rank}.</Text>
                                    <ListItem.Content>
                                        <TouchableOpacity onPress={() => {
                                            if (visible.includes(i)) {
                                                setVisible(visible.filter(v => v !== i));
                                            } else {
                                                setVisible([...visible, i]);
                                            }
                                        }}>
                                            <ListItem.Title style={{...styles.listSubtitle, fontWeight: "bold"}}>
                                                <UserNameComponent user={user}/>
                                            </ListItem.Title>

                                            <ListItem.Subtitle style={styles.listSubtitle}>
                                                {user.stockName || user.symbol}
                                            </ListItem.Subtitle>

                                            {visible.includes(i) && <>
                                                <ListItem.Subtitle style={styles.listSubtitle}>
                                                    {"Börse: " + exchangeList.find(exchange => exchange.Code === user.symbol.split(".")[1])?.Name || user.symbol.split(".")[1]}
                                                </ListItem.Subtitle>
                                                <ListItem.Subtitle style={styles.listSubtitle}>
                                                    {"Land: " + exchangeList.find(exchange => exchange.Code === user.symbol.split(".")[1])?.Country}
                                                </ListItem.Subtitle>
                                                <ListItem.Subtitle style={styles.listSubtitle}>
                                                    {"Startkurs: " + currency2string(parseFloat(selectedChallenge.challengeUserData.find(cud => cud.email === user.email)?.price), exchangeList.find(exchange => exchange.Code === user.symbol.split(".")[1])?.Currency)}
                                                </ListItem.Subtitle>
                                                <ListItem.Subtitle style={styles.listSubtitle}>
                                                    {"Kurs: " + currency2string(parseFloat(user.price), exchangeList.find(exchange => exchange.Code === user.symbol.split(".")[1])?.Currency)}
                                                </ListItem.Subtitle>
                                                <ListItem.Subtitle style={styles.listSubtitle}>
                                                    {"Kursalter: " + time2string(moment().diff(user.timestamp * 1000, "minutes"))}
                                                </ListItem.Subtitle>
                                            </>}
                                        </TouchableOpacity>

                                        {visible.includes(i) && <>
                                            <ListItem.Subtitle style={styles.listSubtitle}>
                                                <Button
                                                    onPress={() => Linking.openURL("https://aktie.traderfox.com/visualizations/" + user.symbol.split(".")[0])}
                                                    buttonStyle={{
                                                        backgroundColor: Colors.mediumGray,
                                                        padding: 0,
                                                        margin: 0
                                                    }}
                                                    titleStyle={{
                                                        color: Colors.white,
                                                        fontSize: 12,
                                                        padding: 0,
                                                        margin: 0
                                                    }}
                                                >
                                                    mehr auf Traderfox
                                                </Button>
                                            </ListItem.Subtitle>
                                        </>
                                        }

                                    </ListItem.Content>
                                    <ListItem.Subtitle style={{
                                        ...styles.listPerformance,
                                        color: user.performance > 0 ? Colors.winGreen : user.performance == 0 ? Colors.white : Colors.loseRed,
                                        fontSize: rank < 3 ? 20 : 16
                                    }}>
                                        {percent2string(user.performance)}
                                    </ListItem.Subtitle>
                                </ListItem>
                            ))
                    }
                </>
            </View>

        </LSOViewWidget>
    </>
}


const styles = StyleSheet.create({
    listSubtitle: {
        color: Colors.white,
        fontSize: 12,
    },
    listPerformance: {
        color: Colors.white,
        fontWeight: "bold",
    },
    listItemContentContainer: {
        padding: 5,
        backgroundColor: "transparent",
        borderBottomWidth: 1,
        borderColor: Colors.mediumGray
    },
    challengeText: {
        fontSize: 18,
        fontWeight: "bold",
        color: Colors.white,
        marginTop: 20
    },
    numberPodestText: {
        fontSize: 28,
        color: Colors.white,
        fontWeight: "bold",
        width: 30,
    },
    numberText: {
        fontSize: 18,
        color: Colors.white,
        fontWeight: "bold",
        width: 30,
    }
});