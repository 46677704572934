export const Colors = {
    orange: '#f57c00',
    blue: '#039be5',
    blackGrey: '#222222',
    black: "#000000",
    white: '#ffffff',
    mediumGray: '#6e6869',
    darkGray: '#404040',
    red: '#fc5c65',
    winGreen: "#b5cb72",
    loseRed: "#8c556a",
    button: "#b5cb72"
};
