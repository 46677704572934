import * as React from "react";
import {Platform, useWindowDimensions} from "react-native";
import RenderHTML from "react-native-render-html";
import {Colors} from "../config";
import {MixedStyleDeclaration} from "@native-html/css-processor/src/CSSProcessor";

export const HtmlView = (props: { html: string, style?: MixedStyleDeclaration }) => {
    const {width} = useWindowDimensions();

    if (Platform.OS === "web") {
        return <div
            style={{color: Colors.white, fontFamily: "Montserrat_400Regular", fontSize: "smaller", width: "100%"}}
            dangerouslySetInnerHTML={{__html: props.html}}>
        </div>
    }

    return <RenderHTML
        contentWidth={width}
        baseStyle={{color: Colors.white, ...props.style}}
        source={{html: props.html}}
    />


}