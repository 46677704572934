import React from "react";
import {LSOViewWidget} from "../../components/LSOViewWidget";
import {StyleSheet, View} from "react-native";
import {auth, Colors} from "../../config";
import {KeyboardAwareScrollView} from "react-native-keyboard-aware-scroll-view";
import {ChallengeFooter} from "./components/ChallengeFooter";
import {Button, Dialog, Input, Text} from "@rneui/base";
import {ChallengeService} from "./services/ChallengeService";
import moment from "moment";
import {DatePicker} from "../../components/DatePicker";
import {MainChallengeLogo} from "./components/MainChallengeLogo";
import {ChallengeType} from "./model/ChallengeType";
import {LoSCheckbox} from "../../components/LoSCheckbox";
import {useNavigation} from "@react-navigation/native";

export const ChallengeEditComponent = (props: {
    onCreateChallenge: (ChallengeType) => void
}) => {
    const [name, setName] = React.useState<string>("")
    const [description, setDescription] = React.useState<string>("")
    const [details, setDetails] = React.useState<string>("")
    const [startDate, setStartDate] = React.useState<Date>(moment().add(1, "days").toDate())
    const [endDate, setEndDate] = React.useState<Date>(moment().add(1, "week").toDate())
    const [confirmDialogVisible, setConfirmDialogVisible] = React.useState<boolean>(false);
    const [negativeWins, setNegativeWins] = React.useState<boolean>(false);
    const navigation = useNavigation();

    const isChallengeValid = () => {
        return name.length >= 1 && description.length >= 1 && details.length >= 1;
    }

    const collectChallengeData = (): ChallengeType => {
        return {
            name,
            description,
            details,
            start: startDate.toISOString(),
            end: endDate.toISOString(),
            creator: {
                id: auth.currentUser.uid,
                displayName: auth.currentUser.displayName,
                email: auth.currentUser.email
            },
            mode: {
                negativeWins
            }
        }
    }

    return <><LSOViewWidget wideStyle text={<Button
        title={"Challenge erstellen"}
        disabled={!isChallengeValid()}
        color={Colors.button}
        titleStyle={{
            color: Colors.mediumGray,
            fontFamily: "Montserrat_700Bold"
        }}
        style={{}}
        onPress={async () => {
            await props.onCreateChallenge(collectChallengeData());

            setName("")
            setDescription("")
            setDetails("")
            setStartDate(new Date())
            setEndDate(new Date())

            setConfirmDialogVisible(true)
        }}
    />}>

        <Text style={styles.label}>Name der Challenge</Text>
        <Input
            placeholder='Name der Challenge'
            onChangeText={setName}
            style={styles.input}
        />

        <Text style={styles.label}>Start</Text>
        <DatePicker date={startDate} onChange={setStartDate}/>

        <Text style={styles.label}>Ende</Text>
        <DatePicker date={endDate} onChange={setEndDate}/>

        <Text style={styles.label}>Beschreibung</Text>
        <Input
            placeholder='Beschreibung der Challenge'
            onChangeText={setDescription}
            style={styles.multiLineInput}
            multiline
        />

        <Text style={styles.label}>Details</Text>
        <Input
            placeholder='Details der Challenge'
            onChangeText={setDetails}
            style={styles.multiLineInput}
            multiline
        />

        <LoSCheckbox value={negativeWins} label="negative Performance gewinnt"
                     onValueChange={() => setNegativeWins(!negativeWins)}/>

    </LSOViewWidget>

        <Dialog
            isVisible={confirmDialogVisible}
            onBackdropPress={() => {
                setConfirmDialogVisible(false)

                if (navigation) {
                    // @ts-ignore
                    navigation.navigate("Challenges")
                }
            }}
        >
            <Dialog.Title titleStyle={{color: Colors.white}} title="Challenge gespeichert"/>
            <Text style={{color: Colors.white}}>Ihre neue Challenge wurde gespeichert.</Text>
            <Dialog.Actions>
                <Dialog.Button title="ok" onPress={() => {
                    setConfirmDialogVisible(false)

                    if (navigation) {
                        // @ts-ignore
                        navigation.navigate("Challenges")
                    }
                }}/>
            </Dialog.Actions>
        </Dialog>
    </>
}

export const ChallengeCreateScreen = () => {

    const onCreateChallenge = async (challenge: ChallengeType) => {
        if (auth.currentUser) {
            await new ChallengeService().createChallenge(challenge);
        }
    }

    return <>
        <View style={styles.mainView}>

            <KeyboardAwareScrollView enableOnAndroid={true}>
                <MainChallengeLogo text={"Challenge-Erstellen"}/>

                <ChallengeEditComponent onCreateChallenge={onCreateChallenge}/>

            </KeyboardAwareScrollView>

            <ChallengeFooter/>

        </View>
    </>
}


const styles = StyleSheet.create({
    mainView: {
        flex: 1
    },
    label: {
        color: Colors.white,
        textAlign: "left"
    },
    input: {
        color: Colors.white,
    },
    multiLineInput: {
        color: Colors.white,
        height: 100,
    }
});