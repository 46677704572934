import {StyleSheet, View} from "react-native";
import {KeyboardAwareScrollView} from "react-native-keyboard-aware-scroll-view";
import {LSOViewWidget} from "../components/LSOViewWidget";
import * as React from "react";
import {useContext} from "react";
import {AirbnbRating, Button, Input, Text} from "@rneui/base";
import {Colors} from "../config";
import {StockService} from "./challenge/services/StockService";
import {AuthenticatedUserContext} from "../providers";

const sendFeedback = (rating: number, text: string, email: string, userName: string) => {

    new StockService().sendMail("Feedback", "Feedback über LoS", `
E-Mail: ${email}<br/>
Name: ${userName}<br/>
Text:<br/>
${text.replaceAll("\n", "<br/>")}
<br/><br/>
Rating: ${rating} Sterne`)
}

export const FeedbackScreen = ({navigation}) => {
    const [feedback, setFeedback] = React.useState<string>()
    const [rating, setRating] = React.useState<number>(3)
    const {user, userName} = useContext(AuthenticatedUserContext);

    return <>
        <View style={styles.mainView}>

            <KeyboardAwareScrollView enableOnAndroid={true}>
                <LSOViewWidget text={"Feedback"} children={undefined}/>

                <LSOViewWidget wideStyle text={<Button
                    title={"Feedback senden"}
                    color={Colors.button}
                    titleStyle={{
                        color: Colors.mediumGray,
                        fontFamily: "Montserrat_700Bold"
                    }}
                    style={{}}
                    onPress={() => {
                        sendFeedback(rating, feedback, user?.email || "", userName);
                        if (navigation) {
                            navigation.navigate("Challenges")
                        }
                    }}
                />}>
                    <Input
                        placeholder='Schreib uns gern hier ein Feedback'
                        onChangeText={setFeedback}
                        style={styles.input}
                        multiline
                    />
                    <Text style={styles.text}>Wie findest du die App?</Text>
                    <AirbnbRating onFinishRating={setRating} defaultRating={rating}/>

                </LSOViewWidget>
            </KeyboardAwareScrollView>
        </View>
    </>
}

const styles = StyleSheet.create({
    mainView: {},
    text: {
        color: Colors.white,
        fontSize: 18
    },
    input: {
        color: Colors.white,
        height: 200,
    }
});