import React, {useContext, useEffect} from "react";
import {ChallengeContext} from "../../providers";
import {useRoute} from "@react-navigation/native";
import {StyleSheet, View} from "react-native";
import {KeyboardAwareScrollView} from "react-native-keyboard-aware-scroll-view";
import {Colors} from "../../config";
import {ChallengeFooter} from "./components/ChallengeFooter";
import {ChallengeConfirmComponent} from "./components/ChallengeConfirmComponent";
import {MainChallengeLogo} from "./components/MainChallengeLogo";


export const ChallengeConfirmScreen = ({navigation}) => {
    const {setChallengeId, selectedChallenge} = useContext(ChallengeContext);
    const route = useRoute();

    useEffect(() => {
        if (route.name === "ChallengeInfo" && route.params["challengeId"] !== undefined) {
            setChallengeId(decodeURI(route.params["challengeId"]));
        }
    }, [route])

    return <>
        <View style={styles.mainView}>

            <KeyboardAwareScrollView enableOnAndroid={true}>
                <MainChallengeLogo text={selectedChallenge?.name || "Challenge-Mode"}/>
                <ChallengeConfirmComponent navigation={navigation}/>
            </KeyboardAwareScrollView>
            <ChallengeFooter/>
        </View>
    </>
}

const styles = StyleSheet.create({
    descriptionText: {
        color: Colors.white,
        width: "100%",
        textAlign: "left",
    },
    listItemContentContainer: {
        padding: 5,
        backgroundColor: "transparent",
        borderBottomWidth: 0
    },
    challengeText: {
        fontSize: 22,
        color: Colors.white,
        marginTop: 20,
        marginBottom: 10,
        fontFamily: "Montserrat_700Bold"
    },
    mainView: {
        flex: 1
    }
});