import {StyleSheet, Text, View} from "react-native";
import React from "react";
import {Colors} from "../config";

const TriangleCorner = (props: { style }) => {
    return <View style={[styles.triangleCorner, props.style]}/>;
};
const TriangleCornerBottomRight = () => {
    return <TriangleCorner style={styles.triangleCornerBottomRight}/>;
};
const TriangleCornerBottomLeft = () => {
    return <TriangleCorner style={styles.triangleCornerBottomLeft}/>;
};

export const LSOViewWidget = (props: { children, wideStyle?: boolean, text?: string | React.ReactElement, viewProp?: any }) => {
    let style = props.wideStyle ? styles.loginFormOverflowWide : styles.loginFormOverflow;
    if (props.text) {
        style = {...style, marginBottom: 0}
    }
    return <>
        <View style={style}>
            <View style={{...styles.loginForm, ...props.viewProp}}>
                {props.children}
            </View>
        </View>
        {props.text && <>
            <View style={{
                flexDirection: "row",
                marginLeft: props.wideStyle ? 10 : 50,
                marginRight: props.wideStyle ? 30 : 70,
            }}>
                <TriangleCornerBottomRight/>
                <View style={{...style, marginTop: 0, marginHorizontal: 0, width: "100%"}}>
                    <View style={styles.loginForm}>
                        <View style={{height: 10}}></View>
                    </View>
                </View>
                <TriangleCornerBottomLeft/>
            </View>

            <View style={props.wideStyle ? styles.bandWide : styles.band}>
                {typeof props.text === "string" ? <Text style={styles.challengeText}>{props.text}</Text> : props.text}
            </View>

            <View style={{...style, marginTop: 0}}>
                <View style={styles.loginForm}>
                    <View style={{height: 10}}></View>
                </View>
            </View>
        </>}

    </>
}

const styles = StyleSheet.create({
    loginFormOverflow: {
        backgroundColor: "#2c3235",
        marginTop: 20,
        marginHorizontal: 60,
        paddingHorizontal: 10,
        marginBottom: 20,
    },
    loginFormOverflowWide: {
        backgroundColor: "#2c3235",
        marginTop: 20,
        marginHorizontal: 20,
        paddingHorizontal: 10,
        marginBottom: 20,
    },
    loginForm: {
        alignItems: 'center',
        backgroundColor: "#343f46",
        paddingHorizontal: 20
    },
    bandWide: {
        backgroundColor: "#454545",
        marginHorizontal: 10,
        paddingHorizontal: 10,
    },
    band: {
        backgroundColor: "#454545",
        marginHorizontal: 50,
        paddingHorizontal: 10,
    },
    challengeText: {
        fontSize: 18,
        color: Colors.white,
        marginTop: 10,
        marginBottom: 10,
        textAlign: "center",
        fontFamily: "Montserrat_800ExtraBold"
    },
    triangleCornerBottomRight: {
        transform: [{rotate: "180deg"}],
    },
    triangleCornerBottomLeft: {
        transform: [{rotate: "270deg"}],
    },
    triangleCorner: {
        width: 0,
        height: 0,
        backgroundColor: "transparent",
        borderStyle: "solid",
        borderRightWidth: 10,
        borderTopWidth: 10,
        borderRightColor: "transparent",
        borderTopColor: "#454545",
    },
});