import React from "react";
import {LSOViewWidget} from "../../../components/LSOViewWidget";
import {Image, StyleSheet} from "react-native";
import {Images} from "../../../config";

export const MainChallengeLogo = (props: { text: string }) => {

    return <LSOViewWidget text={props.text}>
        <Image source={Images.logoChallenge} style={styles.mainLogo}/>
    </LSOViewWidget>
}

const styles = StyleSheet.create({
    mainLogo: {
        marginTop: 20,
        width: 180,
        height: 180
    }
});