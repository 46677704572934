import React from 'react';
import {Image, StyleSheet} from 'react-native';

export const Logo = ({uri}) => {
    return <Image source={uri} style={styles.footerImage}/>;
};

const styles = StyleSheet.create({
    footerImage: {
        width: 200,
        height: 200
    }
});
