import React from 'react';
import {TextInput as RNTextInput} from 'react-native';

import {View} from './View';
import {Icon} from './Icon';
import {Button} from './Button';
import {Colors} from '../config';

export const TextInput = (
    {
        width = '100%',
        leftIconName,
        rightIcon,
        handlePasswordVisibility,
        ...otherProps
    }) => {
    return (
        <View
            style={{
                borderBottomWidth: 1,
                backgroundColor: "transparent",
                flexDirection: 'row',
                padding: 12,
                marginVertical: 4,
                paddingHorizontal: 3,
                borderWidth: 0,
                borderColor: Colors.white,
                color: Colors.white,
                width: "100%"
            }}
            isSafe={true}
        >
            {leftIconName ? (
                <Icon
                    name={leftIconName}
                    size={22}
                    color={Colors.mediumGray}
                    style={{marginRight: 10}}
                />
            ) : null}
            <RNTextInput
                style={{
                    flex: 1,
                    width: '100%',
                    fontSize: 18,
                    color: Colors.white
                }}
                placeholderTextColor={Colors.mediumGray}
                {...otherProps}
            />
            {rightIcon ? (
                <Button onPress={handlePasswordVisibility} title={undefined} style={undefined}>
                    <Icon
                        name={rightIcon}
                        size={22}
                        color={Colors.mediumGray}
                        style={{marginRight: 10}}
                    />
                </Button>
            ) : null}
        </View>
    );
};
