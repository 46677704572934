import React, {useCallback, useContext, useEffect} from "react";
import {AuthenticatedUserContext, ChallengeContext} from "../../providers";
import {GiftedChat} from "react-native-gifted-chat";
import {auth} from "../../config";
import {useRoute} from "@react-navigation/native";
import {ChallengeFooter} from "./components/ChallengeFooter";
import {ChallengeService} from "./services/ChallengeService";
import {Platform} from "react-native";

export const ChallengeChatScreen = () => {
    const {challengeId, setChallengeId, messages, challengeUser, setChallengeUser} = useContext(ChallengeContext);
    const {user, profileUrl} = useContext(AuthenticatedUserContext)
    const route = useRoute();

    useEffect(() => {
        if (route.name === "ChallengeChat" && route.params["challengeId"] !== undefined) {
            setChallengeId(decodeURI(route.params["challengeId"]));
        }
    }, [route])

    const onSend = useCallback((newMessages = []) => {
        if (challengeId && newMessages && newMessages.length >= 1) {
            newMessages.forEach(message => new ChallengeService().createChallengeChatMessage(challengeId, message));
        }
    }, [challengeId])

    useEffect(() => {
        const nrMessages = messages.length;
        if (challengeUser && (challengeUser.nrReadMessages || 0) < nrMessages) {
            console.log("set message counter for user to", nrMessages);
            challengeUser.nrReadMessages = nrMessages;
            setChallengeUser(challengeUser)
            new ChallengeService().updateUserNrMessages(challengeId, user.uid, nrMessages)
        }
    }, [messages, challengeUser])

    return <>
        <GiftedChat
            messages={messages}
            onSend={messages => onSend(messages)}
            renderUsernameOnMessage
            showUserAvatar
            // @ts-ignore
            messagesContainerStyle={Platform.OS === 'web' ? {wordBreak: "break-word"} : {}}
            user={{
                _id: auth.currentUser.uid,
                name: auth?.currentUser?.displayName || auth?.currentUser?.email,
                avatar: profileUrl
            }}
        />

        <ChallengeFooter/>
    </>
}