import React from 'react';
import {SafeAreaProvider} from 'react-native-safe-area-context';

import {RootNavigator} from './navigation';
import {AuthenticatedUserProvider} from './providers';
import {
    Montserrat_400Regular,
    Montserrat_500Medium,
    Montserrat_700Bold,
    Montserrat_800ExtraBold,
    useFonts
} from '@expo-google-fonts/montserrat';

import Hotjar from '@hotjar/browser';
import * as Sentry from 'sentry-expo';

Sentry.init({
    dsn: 'https://dd09c30c73a84d0492567da78131f378@o4504452905697280.ingest.sentry.io/4504452912185344',
    enableInExpoDevelopment: false,
    debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});

const siteId = 3523286;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

const App = () => {
    let [fontsLoaded] = useFonts({
        Montserrat_400Regular,
        Montserrat_500Medium,
        Montserrat_700Bold,
        Montserrat_800ExtraBold
    });

    if (!fontsLoaded) {
        return null;
    }

    return (
        <AuthenticatedUserProvider>
            <SafeAreaProvider>
                <RootNavigator/>
            </SafeAreaProvider>
        </AuthenticatedUserProvider>
    );
};

export default App;
