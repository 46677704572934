import React, {useState} from 'react';
import {Image, StyleSheet, Text} from 'react-native';
import {Formik} from 'formik';
import {sendPasswordResetEmail} from 'firebase/auth';

import {passwordResetSchema} from '../utils';
import {auth, Colors, Images} from '../config';
import {Button, FormErrorMessage, TextInput, View} from '../components';
import {LSOViewWidget} from "../components/LSOViewWidget";

export const ForgotPasswordScreen = ({navigation}) => {
    const [errorState, setErrorState] = useState('');

    const handleSendPasswordResetEmail = values => {
        const {email} = values;

        sendPasswordResetEmail(auth, email)
            .then(() => {
                console.log('Success: Password Reset Email sent.');
                navigation.navigate('Login');
            })
            .catch(error => setErrorState(error.message));
    };

    return (
        <View isSafe style={styles.container}>

            {/* LogoContainer: consits app logo and screen title */}
            <View style={styles.logoContainer} isSafe={true}>
                <Image source={Images.logoAndBackground} style={styles.mainLogo}/>
                <Text style={styles.screenTitle}>League of Stocks</Text>
            </View>

            <LSOViewWidget>
                <Formik
                    initialValues={{email: ''}}
                    validationSchema={passwordResetSchema}
                    onSubmit={values => handleSendPasswordResetEmail(values)}
                >
                    {({
                          values,
                          touched,
                          errors,
                          handleChange,
                          handleSubmit,
                          handleBlur
                      }) => (
                        <>
                            {/* Email input field */}
                            <TextInput
                                name='email'
                                leftIconName={undefined}
                                placeholder='Enter email'
                                autoCapitalize='none'
                                keyboardType='email-address'
                                textContentType='emailAddress'
                                value={values.email}
                                onChangeText={handleChange('email')}
                                onBlur={handleBlur('email')} rightIcon={undefined}
                                handlePasswordVisibility={undefined}/>
                            <FormErrorMessage error={errors.email} visible={touched.email}/>
                            {/* Display Screen Error Mesages */}
                            {errorState !== '' ? (
                                <FormErrorMessage error={errorState} visible={true}/>
                            ) : null}
                            {/* Password Reset Send Email  button */}
                            <Button style={styles.button} onPress={handleSubmit} title={undefined}>
                                <Text style={styles.buttonText}>Send Reset Email</Text>
                            </Button>
                        </>
                    )}
                </Formik>
            </LSOViewWidget>

            {/* Button to navigate to Login screen */}
            <Button
                style={styles.borderlessButtonContainer}
                borderless
                title={'Go back to Login'}
                onPress={() => navigation.navigate('Login')} children={undefined}/>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.black,
        paddingHorizontal: 12
    },
    innerContainer: {
        alignItems: 'center'
    },
    screenTitle: {
        fontSize: 32,
        fontWeight: '700',
        color: Colors.black,
        paddingTop: 20
    },
    button: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 8,
        backgroundColor: "#222425",
        padding: 10,
        borderRadius: 0
    },
    buttonText: {
        fontSize: 20,
        color: Colors.white,
        fontWeight: '700'
    },
    borderlessButtonContainer: {
        marginTop: 16,
        alignItems: 'center',
        justifyContent: 'center'
    },
    logoContainer: {
        alignItems: 'center'
    },
    mainLogo: {
        marginTop: 20,
        width: "100%",
        height: 180,
        resizeMode: "stretch"
    },
});
