import React, {useContext} from "react";
import {ChallengeContext} from "../../../providers";
import {LSOViewWidget} from "../../../components/LSOViewWidget";
import {Colors} from "../../../config";
import {Button} from "@rneui/base";
import {HtmlView} from "../../../components/HtmlView";


export const ChallengeConfirmComponent = ({navigation = undefined}) => {
    const {selectedChallenge, updateUserConfirmed, challengeUser, challengeId} = useContext(ChallengeContext);

    if (!selectedChallenge) {
        return <></>
    }

    return <>
        <LSOViewWidget wideStyle viewProp={{alignItems: 'flex-start'}}>
            <HtmlView html={selectedChallenge.description}/>
        </LSOViewWidget>
        <LSOViewWidget wideStyle
                       viewProp={{alignItems: 'flex-start'}}
                       text={<Button
                           title={"LET'S GO"}
                           color={Colors.button}
                           titleStyle={{
                               color: Colors.mediumGray,
                               fontFamily: "Montserrat_700Bold"
                           }}
                           style={{}}
                           onPress={() => {
                               if (!challengeUser?.hasConfirmed) {
                                   updateUserConfirmed();
                               }
                               if (navigation) {
                                   navigation.navigate("Challenge", {challengeId: challengeId})
                               }
                           }}
                       />}>
            <HtmlView html={selectedChallenge.details}/>
        </LSOViewWidget>
    </>
}