import React, {useContext, useEffect} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {ChallengeComponent} from "./index";
import {ChallengeFooter} from "./components/ChallengeFooter";
import {useRoute} from "@react-navigation/native";
import {ChallengeContext} from "../../providers";


export const ChallengeScreen = () => {

    const {setChallengeId} = useContext(ChallengeContext);
    const route = useRoute();

    useEffect(() => {
        if (route.name === "Challenge" && route.params["challengeId"] !== undefined) {
            setChallengeId(decodeURI(route.params["challengeId"]));
        }
    }, [route])

    return (
        <View style={styles.container}>
            <ScrollView>
                <ChallengeComponent/>
            </ScrollView>
            <ChallengeFooter/>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#000000"
    },
    footerImage: {
        flex: 1,
    },
});
