import React, {useContext} from "react";
import {ChallengeContext} from "../../../providers";
import {StyleSheet, View} from "react-native";
import {TouchableOpacity} from "react-native-gesture-handler";
import {AntDesign, Entypo} from '@expo/vector-icons';
import {useNavigation, useRoute} from "@react-navigation/native";
import {Badge, Dialog, Text} from "@rneui/base";
import {auth, Colors} from "../../../config";
import {ChallengeService} from "../services/ChallengeService";

export const ChallengeFooter = () => {

    const {
        setChallengeId,
        setSelectedChallenge,
        selectedChallenge,
        challengeId,
        messages,
        challengeUser
    } = useContext(ChallengeContext);
    const [deleteConfirmDialogVisible, setDeleteConfirmDialogVisible] = React.useState<boolean>(false);

    const navigation = useNavigation();
    const route = useRoute();

    const onRemoveChallenge = async () => {
        await new ChallengeService().removeChallenge(challengeId);
        setDeleteConfirmDialogVisible(false)

        if (navigation) {
            // @ts-ignore
            navigation.navigate("Challenges")
        }
    }

    return <View style={styles.footerContainer}>
        {(selectedChallenge || route.name === "ChallengeCreate") && <View style={{paddingVertical: 10}}>
            <TouchableOpacity onPress={() => {
                if (route.name === "Challenge") {
                    setChallengeId()
                    setSelectedChallenge()
                    // @ts-ignore
                    navigation.navigate("Challenges")
                } else {
                    // @ts-ignore
                    navigation.navigate("Challenge", {challengeId: challengeId})
                }
            }
            }>
                <Entypo name="arrow-bold-left" size={48} color="grey" style={styles.menuLogo}/>
            </TouchableOpacity>
        </View>}
        {route.name === "Challenges" && <View style={{paddingVertical: 10, alignItems: "flex-end", width: "100%"}}>
            <TouchableOpacity onPress={() => {
                // @ts-ignore
                navigation.navigate("ChallengeCreate", {challengeId: challengeId})
            }
            }>
                <AntDesign name="pluscircle" size={48} color="grey" style={styles.menuLogo}/>
            </TouchableOpacity>
        </View>}
        {selectedChallenge?.creator && selectedChallenge?.creator.id === auth.currentUser.uid && route.name !== "ChallengeInfo" &&
            <View style={{paddingVertical: 10}}>
                <TouchableOpacity onPress={() => {
                    setDeleteConfirmDialogVisible(true)
                }
                }>
                    <AntDesign name="delete" size={48} color="red" style={styles.menuLogo}/>
                </TouchableOpacity>
            </View>}
        {selectedChallenge && route.name !== "ChallengeInfo" &&
            <View style={{paddingVertical: 10, flexDirection: "row"}}>
                {route.name !== "ChallengeChat" && <TouchableOpacity onPress={() => {
                    // @ts-ignore
                    navigation.navigate("ChallengeChat", {challengeId: challengeId})
                }
                }>
                    <Entypo name="chat" size={48} color="grey" style={styles.menuLogo}/>
                    {messages.length - (challengeUser?.nrReadMessages || 0) > 0 &&
                        <Badge value={`${messages.length - (challengeUser?.nrReadMessages || 0)}`} status={"success"}
                               containerStyle={{position: "absolute", left: 10}}/>}
                </TouchableOpacity>}
                <TouchableOpacity onPress={() => {
                    // @ts-ignore
                    navigation.navigate("ChallengeInfo", {challengeId: challengeId})
                }
                }>
                    <AntDesign name="infocirlce" size={48} color="grey" style={styles.menuLogo}/>
                </TouchableOpacity>
            </View>}

        <Dialog
            isVisible={deleteConfirmDialogVisible}
            onBackdropPress={() => {
                setDeleteConfirmDialogVisible(false)
            }}
        >
            <Dialog.Title titleStyle={{color: Colors.white}} title="Challenge löschen?"/>
            <Text style={{color: Colors.white}}>Soll Ihre Challenge "{selectedChallenge?.name}" wirklich gelöscht
                werden?</Text>
            <Dialog.Actions>
                <Dialog.Button titleStyle={{color: Colors.red}} title="löschen" onPress={onRemoveChallenge}/>
                <Dialog.Button title="abbrechen" onPress={() => setDeleteConfirmDialogVisible(false)}/>
            </Dialog.Actions>
        </Dialog>
    </View>
}

const styles = StyleSheet.create({
    menuLogo: {
        marginHorizontal: 20
    },
    footerImage: {
        flex: 1,
    },
    footerContainer: {
        backgroundColor: "#2b2d2c",
        flexDirection: "row",
        width: "100%",
        justifyContent: 'space-between',
        borderBottomWidth: 0
    }
});