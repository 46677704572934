import memoizee from "memoizee";

/**
 * helper method to stringify all arguments for comparison
 *
 * @param args arguments of the method
 *
 * example:
 *
 *
 @Memoizee({promise: true, normalizer: MemoizeeArgsJson2String})
 */
export function MemoizeeArgsJson2String(args: any) {
    // args is arguments object as accessible in memoized function
    return JSON.stringify(args[0]);
}

/**
 * wrapping the method with memoizee to hand
 * @param options
 */
export function Memoizee(options?: memoizee.Options<any>) {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        let originalMethod = descriptor.value;
        //wrapping the original method
        descriptor.value = memoizee(originalMethod, options);
    }
}
