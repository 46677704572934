export const exchangeList = [
    {
        "Name": "USA Stocks",
        "Code": "US",
        "OperatingMIC": "XNAS, XNYS",
        "Country": "USA",
        "Currency": "USD",
        "CountryISO2": "US",
        "CountryISO3": "USA"
    },
    {
        "Name": "USA Stocks",
        "Code": "PINK",
        "OperatingMIC": "XNAS, XNYS",
        "Country": "USA",
        "Currency": "USD",
        "CountryISO2": "US",
        "CountryISO3": "USA"
    },
    {
        "Name": "NASDAQ",
        "Code": "NASDAQ",
        "OperatingMIC": "XNAS, XNYS",
        "Country": "USA",
        "Currency": "USD",
        "CountryISO2": "US",
        "CountryISO3": "USA"
    },
    {
        "Name": "London Exchange",
        "Code": "LSE",
        "OperatingMIC": "XLON",
        "Country": "UK",
        "Currency": "GBP",
        "CountryISO2": "GB",
        "CountryISO3": "GBR"
    },
    {
        "Name": "Toronto Exchange",
        "Code": "TO",
        "OperatingMIC": "XTSE",
        "Country": "Canada",
        "Currency": "CAD",
        "CountryISO2": "CA",
        "CountryISO3": "CAN"
    },
    {
        "Name": "NEO Exchange",
        "Code": "NEO",
        "OperatingMIC": "NEOE",
        "Country": "Canada",
        "Currency": "CAD",
        "CountryISO2": "CA",
        "CountryISO3": "CAN"
    },
    {
        "Name": "TSX Venture Exchange",
        "Code": "V",
        "OperatingMIC": "XTSX",
        "Country": "Canada",
        "Currency": "CAD",
        "CountryISO2": "CA",
        "CountryISO3": "CAN"
    },
    {
        "Name": "Berlin Exchange",
        "Code": "BE",
        "OperatingMIC": "XBER",
        "Country": "Germany",
        "Currency": "EUR",
        "CountryISO2": "DE",
        "CountryISO3": "DEU"
    },
    {
        "Name": "Hamburg Exchange",
        "Code": "HM",
        "OperatingMIC": "XHAM",
        "Country": "Germany",
        "Currency": "EUR",
        "CountryISO2": "DE",
        "CountryISO3": "DEU"
    },
    {
        "Name": "XETRA Exchange",
        "Code": "XETRA",
        "OperatingMIC": "XETR",
        "Country": "Germany",
        "Currency": "EUR",
        "CountryISO2": "DE",
        "CountryISO3": "DEU"
    },
    {
        "Name": "Dusseldorf Exchange",
        "Code": "DU",
        "OperatingMIC": "XDUS",
        "Country": "Germany",
        "Currency": "EUR",
        "CountryISO2": "DE",
        "CountryISO3": "DEU"
    },
    {
        "Name": "Hanover Exchange",
        "Code": "HA",
        "OperatingMIC": "XHAN",
        "Country": "Germany",
        "Currency": "EUR",
        "CountryISO2": "DE",
        "CountryISO3": "DEU"
    },
    {
        "Name": "Munich Exchange",
        "Code": "MU",
        "OperatingMIC": "XMUN",
        "Country": "Germany",
        "Currency": "EUR",
        "CountryISO2": "DE",
        "CountryISO3": "DEU"
    },
    {
        "Name": "Stuttgart Exchange",
        "Code": "STU",
        "OperatingMIC": "XSTU",
        "Country": "Germany",
        "Currency": "EUR",
        "CountryISO2": "DE",
        "CountryISO3": "DEU"
    },
    {
        "Name": "Frankfurt Exchange",
        "Code": "F",
        "OperatingMIC": "XFRA",
        "Country": "Germany",
        "Currency": "EUR",
        "CountryISO2": "DE",
        "CountryISO3": "DEU"
    },
    {
        "Name": "Luxembourg Stock Exchange",
        "Code": "LU",
        "OperatingMIC": "XLUX",
        "Country": "Luxembourg",
        "Currency": "EUR",
        "CountryISO2": "LU",
        "CountryISO3": "LUX"
    },
    {
        "Name": "Vienna Exchange",
        "Code": "VI",
        "OperatingMIC": "XWBO",
        "Country": "Austria",
        "Currency": "EUR",
        "CountryISO2": "AT",
        "CountryISO3": "AUT"
    },
    {
        "Name": "Borsa Italiana",
        "Code": "MI",
        "OperatingMIC": "XMIL",
        "Country": "Italy",
        "Currency": "EUR",
        "CountryISO2": "IT",
        "CountryISO3": "ITA"
    },
    {
        "Name": "Euronext Paris",
        "Code": "PA",
        "OperatingMIC": "XPAR",
        "Country": "France",
        "Currency": "EUR",
        "CountryISO2": "FR",
        "CountryISO3": "FRA"
    },
    {
        "Name": "Euronext Brussels",
        "Code": "BR",
        "OperatingMIC": "XBRU",
        "Country": "Belgium",
        "Currency": "EUR",
        "CountryISO2": "BE",
        "CountryISO3": "BEL"
    },
    {
        "Name": "Swiss Exchange",
        "Code": "VX",
        "OperatingMIC": "XSWX",
        "Country": "Switzerland",
        "Currency": "CHF",
        "CountryISO2": "CH",
        "CountryISO3": "CHE"
    },
    {
        "Name": "Euronext Amsterdam",
        "Code": "AS",
        "OperatingMIC": "XAMS",
        "Country": "Netherlands",
        "Currency": "EUR",
        "CountryISO2": "NL",
        "CountryISO3": "NLD"
    },
    {
        "Name": "Madrid Exchange",
        "Code": "MC",
        "OperatingMIC": "BMEX",
        "Country": "Spain",
        "Currency": "EUR",
        "CountryISO2": "ES",
        "CountryISO3": "ESP"
    },
    {
        "Name": "Euronext Lisbon",
        "Code": "LS",
        "OperatingMIC": "XLIS",
        "Country": "Portugal",
        "Currency": "EUR",
        "CountryISO2": "PT",
        "CountryISO3": "PRT"
    },
    {
        "Name": "SIX Swiss Exchange",
        "Code": "SW",
        "OperatingMIC": "XSWX",
        "Country": "Switzerland",
        "Currency": "CHF",
        "CountryISO2": "CH",
        "CountryISO3": "CHE"
    },
    {
        "Name": "Oslo Stock Exchange",
        "Code": "OL",
        "OperatingMIC": "XOSL",
        "Country": "Norway",
        "Currency": "NOK",
        "CountryISO2": "NO",
        "CountryISO3": "NOR"
    },
    {
        "Name": "Copenhagen Exchange",
        "Code": "CO",
        "OperatingMIC": "XCSE",
        "Country": "Denmark",
        "Currency": "DKK",
        "CountryISO2": "DK",
        "CountryISO3": "DNK"
    },
    {
        "Name": "Helsinki Exchange",
        "Code": "HE",
        "OperatingMIC": "XHEL",
        "Country": "Finland",
        "Currency": "EUR",
        "CountryISO2": "FI",
        "CountryISO3": "FIN"
    },
    {
        "Name": "Stockholm Exchange",
        "Code": "ST",
        "OperatingMIC": "XSTO",
        "Country": "Sweden",
        "Currency": "SEK",
        "CountryISO2": "SE",
        "CountryISO3": "SWE"
    },
    {
        "Name": "Irish Exchange",
        "Code": "IR",
        "OperatingMIC": "XDUB",
        "Country": "Ireland",
        "Currency": "EUR",
        "CountryISO2": "IE",
        "CountryISO3": "IRL"
    },
    {
        "Name": "Iceland Exchange",
        "Code": "IC",
        "OperatingMIC": "XICE",
        "Country": "Iceland",
        "Currency": "ISK",
        "CountryISO2": "IS",
        "CountryISO3": "ISL"
    },
    {
        "Name": "Prague Stock Exchange ",
        "Code": "PR",
        "OperatingMIC": "XPRA",
        "Country": "Czech Republic",
        "Currency": "CZK",
        "CountryISO2": "CZ",
        "CountryISO3": "CZE"
    },
    {
        "Name": "Tel Aviv Exchange",
        "Code": "TA",
        "OperatingMIC": "XTAE",
        "Country": "Israel",
        "Currency": "ILS",
        "CountryISO2": "IL",
        "CountryISO3": "ISR"
    },
    {
        "Name": "Hong Kong Exchange",
        "Code": "HK",
        "OperatingMIC": "XHKG",
        "Country": "Hong Kong",
        "Currency": "HKD",
        "CountryISO2": "HK",
        "CountryISO3": "HKG"
    },
    {
        "Name": "Korea Stock Exchange",
        "Code": "KO",
        "OperatingMIC": "XKRX",
        "Country": "Korea",
        "Currency": "KRW",
        "CountryISO2": "KR",
        "CountryISO3": "KOR"
    },
    {
        "Name": "KOSDAQ",
        "Code": "KQ",
        "OperatingMIC": "XKOS",
        "Country": "Korea",
        "Currency": "KRW",
        "CountryISO2": "KR",
        "CountryISO3": "KOR"
    },
    {
        "Name": "Budapest Stock Exchange",
        "Code": "BUD",
        "OperatingMIC": "XBUD",
        "Country": "Hungary",
        "Currency": "HUF",
        "CountryISO2": "HU",
        "CountryISO3": "HUN"
    },
    {
        "Name": "Warsaw Stock Exchange",
        "Code": "WAR",
        "OperatingMIC": "XWAR",
        "Country": "Poland",
        "Currency": "PLN",
        "CountryISO2": "PL",
        "CountryISO3": "POL"
    },
    {
        "Name": "Philippine Stock Exchange",
        "Code": "PSE",
        "OperatingMIC": "XPHS",
        "Country": "Philippines",
        "Currency": "PHP",
        "CountryISO2": "PH",
        "CountryISO3": "PHL"
    },
    {
        "Name": "Singapore Exchange",
        "Code": "SG",
        "OperatingMIC": "XSES",
        "Country": "Singapore",
        "Currency": "SGD",
        "CountryISO2": "SG",
        "CountryISO3": "SGP"
    },
    {
        "Name": "Bombay Exchange",
        "Code": "BSE",
        "OperatingMIC": "XBOM",
        "Country": "India",
        "Currency": "INR",
        "CountryISO2": "IN",
        "CountryISO3": "IND"
    },
    {
        "Name": "Johannesburg Exchange",
        "Code": "JSE",
        "OperatingMIC": "XJSE",
        "Country": "South Africa",
        "Currency": "ZAR",
        "CountryISO2": "ZA",
        "CountryISO3": "ZAF"
    },
    {
        "Name": "Karachi Stock Exchange",
        "Code": "KAR",
        "OperatingMIC": "XKAR",
        "Country": "Pakistan",
        "Currency": "PKR",
        "CountryISO2": "PK",
        "CountryISO3": "PAK"
    },
    {
        "Name": "Australia Exchange",
        "Code": "AU",
        "OperatingMIC": "XASX",
        "Country": "Australia",
        "Currency": "AUD",
        "CountryISO2": "AU",
        "CountryISO3": "AUS"
    },
    {
        "Name": "Tokyo Stock Exchange",
        "Code": "TSE",
        "OperatingMIC": "XJPX",
        "Country": "Japan",
        "Currency": "JPY",
        "CountryISO2": "JP",
        "CountryISO3": "JPN"
    },
    {
        "Name": "Chilean Stock Exchange",
        "Code": "SN",
        "OperatingMIC": "XSGO",
        "Country": "Chile",
        "Currency": "CLP",
        "CountryISO2": "CL",
        "CountryISO3": "CHL"
    },
    {
        "Name": "Saudi Arabia Exchange",
        "Code": "SR",
        "OperatingMIC": "XSAU",
        "Country": "Saudi Arabia",
        "Currency": "SAR",
        "CountryISO2": "SA",
        "CountryISO3": "SAU"
    },
    {
        "Name": "Thailand Exchange",
        "Code": "BK",
        "OperatingMIC": "XBKK",
        "Country": "Thailand",
        "Currency": "THB",
        "CountryISO2": "TH",
        "CountryISO3": "THA"
    },
    {
        "Name": "Shanghai Exchange",
        "Code": "SHG",
        "OperatingMIC": "XSHG",
        "Country": "China",
        "Currency": "CNY",
        "CountryISO2": "CN",
        "CountryISO3": "CHN"
    },
    {
        "Name": "Shenzhen Exchange",
        "Code": "SHE",
        "OperatingMIC": "XSHE",
        "Country": "China",
        "Currency": "CNY",
        "CountryISO2": "CN",
        "CountryISO3": "CHN"
    },
    {
        "Name": "Jakarta Exchange",
        "Code": "JK",
        "OperatingMIC": "XIDX",
        "Country": "Indonesia",
        "Currency": "IDR",
        "CountryISO2": "ID",
        "CountryISO3": "IDN"
    },
    {
        "Name": "Athens Exchange",
        "Code": "AT",
        "OperatingMIC": "ASEX",
        "Country": "Greece",
        "Currency": "EUR",
        "CountryISO2": "GR",
        "CountryISO3": "GRC"
    },
    {
        "Name": "NSE (India)",
        "Code": "NSE",
        "OperatingMIC": "XNSE",
        "Country": "India",
        "Currency": "INR",
        "CountryISO2": "IN",
        "CountryISO3": "IND"
    },
    {
        "Name": "Colombo Stock Exchange",
        "Code": "CM",
        "OperatingMIC": "XCOL",
        "Country": "Sri Lanka",
        "Currency": "LKR",
        "CountryISO2": "LK",
        "CountryISO3": "LKA"
    },
    {
        "Name": "Vietnam Stocks",
        "Code": "VN",
        "OperatingMIC": "HSTC",
        "Country": "Vietnam",
        "Currency": "VND",
        "CountryISO2": "VN",
        "CountryISO3": "VNM"
    },
    {
        "Name": "Kuala Lumpur Exchange",
        "Code": "KLSE",
        "OperatingMIC": "XKLS",
        "Country": "Malaysia",
        "Currency": "MYR",
        "CountryISO2": "MY",
        "CountryISO3": "MYS"
    },
    {
        "Name": "Sao Paolo Exchange",
        "Code": "SA",
        "OperatingMIC": "BVMF",
        "Country": "Brazil",
        "Currency": "BRL",
        "CountryISO2": "BR",
        "CountryISO3": "BRA"
    },
    {
        "Name": "Bucharest Stock Exchange",
        "Code": "RO",
        "OperatingMIC": "XBSE",
        "Country": "Romania",
        "Currency": "RON",
        "CountryISO2": "RO",
        "CountryISO3": "ROU"
    },
    {
        "Name": "Buenos Aires Exchange",
        "Code": "BA",
        "OperatingMIC": "XBUE",
        "Country": "Argentina",
        "Currency": "ARS",
        "CountryISO2": "AR",
        "CountryISO3": "ARG"
    },
    {
        "Name": "Mexican Exchange",
        "Code": "MX",
        "OperatingMIC": "XMEX",
        "Country": "Mexico",
        "Currency": "MXN",
        "CountryISO2": "MX",
        "CountryISO3": "MEX"
    },
    {
        "Name": "Zagreb Stock Exchange",
        "Code": "ZSE",
        "OperatingMIC": "XZAG",
        "Country": "Croatia",
        "Currency": "EUR",
        "CountryISO2": "HR",
        "CountryISO3": "HRV"
    },
    {
        "Name": "London IL",
        "Code": "IL",
        "OperatingMIC": "XLON",
        "Country": "UK",
        "Currency": "USD",
        "CountryISO2": "GB",
        "CountryISO3": "GBR"
    },
    {
        "Name": "Istanbul Stock Exchange",
        "Code": "IS",
        "OperatingMIC": "XIST",
        "Country": "Turkey",
        "Currency": "TRY",
        "CountryISO2": "TR",
        "CountryISO3": "TUR"
    },
    {
        "Name": "Cryptocurrencies",
        "Code": "CC",
        "OperatingMIC": "CRYP",
        "Country": "Unknown",
        "Currency": "Unknown",
        "CountryISO2": "",
        "CountryISO3": ""
    },
    {
        "Name": "Indices",
        "Code": "INDX",
        "OperatingMIC": null,
        "Country": "Unknown",
        "Currency": "Unknown",
        "CountryISO2": "",
        "CountryISO3": ""
    },
    {
        "Name": "Commodities",
        "Code": "COMM",
        "OperatingMIC": null,
        "Country": "Unknown",
        "Currency": "Unknown",
        "CountryISO2": "",
        "CountryISO3": ""
    },
    {
        "Name": "Bond Virtual Exchange",
        "Code": "BOND",
        "OperatingMIC": null,
        "Country": "USA",
        "Currency": "USD",
        "CountryISO2": "US",
        "CountryISO3": "USA"
    },
    {
        "Name": "FOREX",
        "Code": "FOREX",
        "OperatingMIC": "CDSL",
        "Country": "Unknown",
        "Currency": "Unknown",
        "CountryISO2": "",
        "CountryISO3": ""
    },
    {
        "Name": "Europe Fund Virtual Exchange",
        "Code": "EUFUND",
        "OperatingMIC": null,
        "Country": "Unknown",
        "Currency": "EUR",
        "CountryISO2": "",
        "CountryISO3": ""
    },
    {
        "Name": "Money Market Virtual Exchange",
        "Code": "MONEY",
        "OperatingMIC": null,
        "Country": "Unknown",
        "Currency": "Unknown",
        "CountryISO2": "",
        "CountryISO3": ""
    },
    {
        "Name": "Government Bonds",
        "Code": "GBOND",
        "OperatingMIC": null,
        "Country": "Unknown",
        "Currency": "Unknown",
        "CountryISO2": "",
        "CountryISO3": ""
    },
    {
        "Name": "Bolsa de Valores de Lima",
        "Code": "LIM",
        "OperatingMIC": "XLIM",
        "Country": "Peru",
        "Currency": "PEN",
        "CountryISO2": "PE",
        "CountryISO3": "PER"
    },
    {
        "Name": "Taiwan OTC Exchange",
        "Code": "TWO",
        "OperatingMIC": "ROCO",
        "Country": "Taiwan",
        "Currency": "TWD",
        "CountryISO2": "TW",
        "CountryISO3": "TWN"
    },
    {
        "Name": "MICEX Moscow Russia",
        "Code": "MCX",
        "OperatingMIC": null,
        "Country": "Russia",
        "Currency": "RUB",
        "CountryISO2": "RU",
        "CountryISO3": "RUS"
    },
    {
        "Name": "EU Bond Virtual Exchange",
        "Code": "EUBOND",
        "OperatingMIC": null,
        "Country": "Belgium",
        "Currency": "EUR",
        "CountryISO2": "BE",
        "CountryISO3": "BEL"
    },
    {
        "Name": "Taiwan Exchange",
        "Code": "TW",
        "OperatingMIC": "XTAI",
        "Country": "Taiwan",
        "Currency": "TWD",
        "CountryISO2": "TW",
        "CountryISO3": "TWN"
    }
]