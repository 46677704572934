import {useEffect, useState} from "react";
import {View} from "react-native";
import Checkbox from "expo-checkbox";
import {Text} from "@rneui/base";
import {Colors} from "../config";


export const LoSCheckbox = (props: { value: boolean, onValueChange: (boolean) => void, label: string }) => {

    const [value, setValue] = useState<boolean>(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    return <View style={{
        backgroundColor: 'transparent',
        flexDirection: "row"
    }}>
        <Checkbox
            style={{
                margin: 8
            }}
            value={value}
            onValueChange={props.onValueChange}
        />
        <Text style={{
            color: Colors.white,
            fontWeight: "300",
            marginTop: 5
        }}>{props.label}</Text>
    </View>
}