import React, {createContext, useEffect, useState} from 'react';
import {User} from "firebase/auth";
import {UserService} from "./UserService";
import {getDownloadURL, getStorage, ref} from "firebase/storage";
import {app} from "../config";

export const AuthenticatedUserContext = createContext<{
    user: User,
    setUser: React.Dispatch<React.SetStateAction<User>>,
    updateUsername: (userName: string) => Promise<void>,
    userName: string,
    profileUrl: string,
    refreshProfileUrl: () => void
}>({} as any);



export const AuthenticatedUserProvider = ({children}) => {
    const [user, setUser] = useState<User>();
    const [userName, setUserName] = useState<string>();

    const [profileUrl, setProfileUrl] = useState<string>();

    const refreshProfileUrl = () => {
        if (user && user.uid) {
            const profilePicRef = ref(getStorage(app), "ProfilePics/" + user?.uid + ".png");
            getDownloadURL(profilePicRef).then((url) => {
                setProfileUrl(url);
            }).catch((error) => {
                setProfileUrl(user?.photoURL)
            });
        }
    }

    useEffect(() => {
        refreshProfileUrl();
    }, [user]);

    useEffect(() => {
        if (user) {
            setUserName(user.displayName || user.email);
            new UserService().getUsername(user.uid).then(value => value && setUserName(value));
        }
    }, [user]);

    const updateUsername = async (userName: string) => {
        if (user) {
            await new UserService().updateUsername(user.uid, userName, user.email);
            setUserName(userName);
        }
    }

    return (
        <AuthenticatedUserContext.Provider
            value={{user, setUser, updateUsername, userName, profileUrl, refreshProfileUrl}}>
            {children}
        </AuthenticatedUserContext.Provider>
    );
};
