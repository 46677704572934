import React, {useState} from 'react';
import {Image, StyleSheet, Text, View} from 'react-native';
import {Formik} from 'formik';
import {GoogleAuthProvider, signInWithCredential, signInWithEmailAndPassword} from 'firebase/auth';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';

import {Button, FormErrorMessage, TextInput} from '../components';
import {auth, Colors, Images} from '../config';
import {useTogglePasswordVisibility} from '../hooks';
import {loginValidationSchema} from '../utils';

import * as WebBrowser from 'expo-web-browser';
import * as Google from 'expo-auth-session/providers/google';
import {LSOViewWidget} from "../components/LSOViewWidget";
import {LoSCheckbox} from "../components/LoSCheckbox";

WebBrowser.maybeCompleteAuthSession();

export const LoginScreen = ({navigation}) => {
    const [errorState, setErrorState] = useState('');
    const {passwordVisibility, handlePasswordVisibility} = useTogglePasswordVisibility();
    const [checkTOAagreed, setCheckTOAagreed] = useState(false);

    const handleLogin = values => {
        const {email, password} = values;
        signInWithEmailAndPassword(auth, email, password).catch(error => setErrorState(error.message));
    };

    const [request, response, promptAsync] = Google.useIdTokenAuthRequest({
        expoClientId: '579806548059-r4m27vpe9dul2vbn7kn585nu3ma3j3e4.apps.googleusercontent.com',
        webClientId: '579806548059-6dcinfnlim308id7tsoifcoji96l4mkn.apps.googleusercontent.com',
        androidClientId: '579806548059-sp8ppcbvnrr0h20jpb0uuk876jlfm2ke.apps.googleusercontent.com',
        clientId: '579806548059-6dcinfnlim308id7tsoifcoji96l4mkn.apps.googleusercontent.com',
    },);

    React.useEffect(() => {
        if (response?.type === 'success') {
            const {id_token} = response.params;
            const credential = GoogleAuthProvider.credential(id_token);
            signInWithCredential(auth, credential)
                .then((userCredential) => {
                    // Signed in
                    const user = userCredential.user;
                    console.log("user", user, "authenticated")
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // The email of the user's account used.
                    const email = error.email;
                    // The AuthCredential type that was used.
                    const credential = GoogleAuthProvider.credentialFromError(error);
                    console.log("error", errorCode, errorMessage, email, credential)
                });
        }
    }, [response]);

    return (<>
        <View style={styles.container}>
            <KeyboardAwareScrollView enableOnAndroid={true}>
                {/* LogoContainer: consits app logo and screen title */}
                <View style={styles.logoContainer}>
                    <Image source={Images.logoAndBackground} style={styles.mainLogo}/>
                    <Text style={styles.screenTitle}>League of Stocks</Text>
                </View>
                <LSOViewWidget>
                    <Formik
                        initialValues={{
                            email: '', password: ''
                        }}
                        validationSchema={loginValidationSchema}
                        onSubmit={values => handleLogin(values)}
                    >
                        {({
                              values, touched, errors, handleChange, handleSubmit, handleBlur
                          }) => (<>
                            {/* Input fields */}
                            <TextInput
                                name='email'
                                placeholder='E-Mail eingeben'
                                autoCapitalize='none'
                                keyboardType='email-address'
                                textContentType='emailAddress'
                                autoFocus={true}
                                value={values.email}
                                onChangeText={handleChange('email')}
                                onBlur={handleBlur('email')} leftIconName={undefined} rightIcon={undefined}
                                handlePasswordVisibility={undefined}/>
                            <FormErrorMessage
                                error={errors.email}
                                visible={touched.email}
                            />
                            <TextInput
                                name='password'
                                placeholder='Passwort eingeben'
                                autoCapitalize='none'
                                autoCorrect={false}
                                secureTextEntry={passwordVisibility}
                                textContentType='password'
                                handlePasswordVisibility={handlePasswordVisibility}
                                value={values.password}
                                onChangeText={handleChange('password')}
                                onBlur={handleBlur('password')} leftIconName={undefined} rightIcon={undefined}/>
                            <FormErrorMessage
                                error={errors.password}
                                visible={touched.password}
                            />
                            {/* Display Screen Error Mesages */}
                            {errorState !== '' ? (<FormErrorMessage error={errorState} visible={true}/>) : null}

                            <LoSCheckbox value={checkTOAagreed} onValueChange={() => setCheckTOAagreed(!checkTOAagreed)}
                                         label="Ja, ich habe die Datenschutzbestimmungen gelesen und erkläre mich mit den AGBs einverstanden"/>

                            {/* Login button */}
                            <Button style={styles.buttonLogin} onPress={handleSubmit}
                                    title={undefined}>
                                <Text style={styles.buttonLoginText}>Anmelden</Text>
                            </Button>
                        </>)}
                    </Formik>

                    <View style={styles.borderlessButtonContainer}>
                        <Text style={styles.flowText}>ODER ANMELDEN MIT </Text>
                    </View>

                    <View style={styles.borderlessButtonContainer}>
                        <Button style={styles.googleButton}
                                onPress={() => {
                                    promptAsync();
                                }}
                                title={undefined}>
                            <Image source={Images.googleLogo} style={styles.loginLogo}/>
                        </Button>
                    </View>

                    {/* Button to navigate to SignupScreen to create a new account */}
                    <Button
                        style={styles.borderlessButtonContainer}
                        borderless
                        title={'Wollen Sie sich registrieren?'}
                        onPress={() => navigation.navigate('Signup')} children={undefined}/>
                    <Button
                        style={styles.borderlessButtonContainer}
                        borderless
                        title={'Passwort vergessen?'}
                        onPress={() => navigation.navigate('ForgotPassword')}
                        children={undefined}
                    />
                </LSOViewWidget>
            </KeyboardAwareScrollView>
        </View>

        {/* App info footer */}
        <View style={styles.footer}>
            <Image source={Images.footerBackground} style={styles.footerImage}/>
        </View>
    </>);
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.black,
        paddingHorizontal: 12
    },
    footer: {
        alignItems: 'center'
    },
    footerImage: {
        width: "100%",
        height: 52,
        backgroundColor: Colors.black

    },
    logoContainer: {
        alignItems: 'center'
    },
    mainLogo: {
        marginTop: 20,
        width: "100%",
        height: 180,
        resizeMode: "stretch"
    },
    screenTitle: {
        fontSize: 32,
        fontWeight: '300',
        color: Colors.white,
        paddingTop: 20,
        display: "none"
    },
    buttonLogin: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 8,
        backgroundColor: "#222425",
        padding: 10,
        borderRadius: 0
    },
    buttonLoginText: {
        fontSize: 16,
        color: Colors.white,
        fontWeight: '300'
    },
    googleButton: {
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 8,
        backgroundColor: Colors.white,
        padding: 10,
        borderRadius: 8,
        width: 64
    },
    buttonText: {
        fontSize: 20,
        color: Colors.white,
        fontWeight: '700'
    },
    borderlessButtonContainer: {
        marginTop: 16,
        alignItems: 'center',
        justifyContent: 'center'
    },
    flowText: {
        color: Colors.white,
        fontWeight: "300",
        fontSize: 10
    },
    checkBoxTOAContainer: {
        backgroundColor: 'transparent',
        flexDirection: "row"
    },
    checkBoxTOAText: {
        color: Colors.white,
        fontWeight: "300"
    },
    checkBoxTOABox: {
        margin: 8
    },
    loginLogo: {
        width: 20,
        height: 20
    }
});
