import {LSOViewWidget} from "../../../components/LSOViewWidget";
import {StyleSheet, Text} from "react-native";
import React from "react";
import {Colors} from "../../../config";
import {ChallengeRankingComponent} from "./ChallengeRankingComponent";


export const ChallengeFinishedComponent = () => {
    return <>
        <LSOViewWidget>
            <Text style={styles.challengeText}>Challenge beendet</Text>
        </LSOViewWidget>
        <ChallengeRankingComponent/>
    </>
}


const styles = StyleSheet.create({
    challengeText: {
        fontSize: 18,
        fontWeight: "bold",
        color: Colors.white,
        marginTop: 20,
        marginBottom: 20
    }
});