import {StyleSheet, View} from "react-native";
import {KeyboardAwareScrollView} from "react-native-keyboard-aware-scroll-view";
import {LSOViewWidget} from "../components/LSOViewWidget";
import * as React from "react";
import {useContext, useState} from "react";
import {Avatar, Button, Dialog, Input, Text} from "@rneui/base";
import {Colors} from "../config";
import {AuthenticatedUserContext} from "../providers";
import {TouchableOpacity} from "react-native-gesture-handler";
import * as ImagePicker from 'expo-image-picker';
import {analytics, app} from "../config/firebase";
import {getStorage, ref, uploadString} from "firebase/storage"
import {logEvent} from "firebase/analytics";

export const UserScreen = () => {
    const {user, userName, updateUsername, profileUrl, refreshProfileUrl} = useContext(AuthenticatedUserContext);
    const [editUserVisible, setEditUserVisible] = useState(false);
    const [newUserName, setNewUserName] = useState(userName);

    const pickImage = async () => {
        // No permissions request is necessary for launching the imageUrl library
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
        });

        console.log(result);

        if (!result.canceled) {
            const dataUrl = result.assets[0].uri
            const profilePicRef = ref(getStorage(app), "ProfilePics/" + user?.uid + ".png");
            uploadString(profilePicRef, dataUrl, 'data_url').then((snapshot) => {
                !__DEV__ && analytics && logEvent(analytics, 'ProfilBild_Upload');
                console.log('Uploaded a data_url string!');
                refreshProfileUrl();
            });
        }
    };

    return <>
        <View style={styles.mainView}>

            <KeyboardAwareScrollView enableOnAndroid={true}>
                <LSOViewWidget text={"Das bist du"} children={undefined}/>

                <LSOViewWidget wideStyle text={<Button
                    title={"Nutzer bearbeiten"}
                    color={Colors.button}
                    titleStyle={{
                        color: Colors.mediumGray,
                        fontFamily: "Montserrat_700Bold"
                    }}
                    style={{}}
                    onPress={() => {
                        setEditUserVisible(true)
                    }}
                />}>

                    <TouchableOpacity onPress={pickImage}>
                        <Avatar
                            size={128}
                            rounded
                            source={{uri: profileUrl}}
                            title={user?.displayName?.charAt(0) || "U"}
                            containerStyle={{backgroundColor: 'grey', margin: 20}}
                        >
                            <Avatar.Accessory size={23}/>
                        </Avatar>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => setEditUserVisible(true)}>
                        <Text style={styles.text}>Dein Name:</Text><Input disabled={true} value={userName}
                                                                          style={{color: Colors.white}}/>
                    </TouchableOpacity>

                </LSOViewWidget>
            </KeyboardAwareScrollView>
        </View>

        <Dialog
            isVisible={editUserVisible}
            onBackdropPress={() => setEditUserVisible(false)}
        >
            <Dialog.Title title="Nutzernamen anpassen" titleStyle={{color: Colors.white}}/>
            <Input value={newUserName} style={{color: Colors.white}} onChangeText={setNewUserName}/>
            <Dialog.Actions>
                <Dialog.Button title="Speichern" onPress={() => {
                    setEditUserVisible(false)
                    updateUsername(newUserName);
                }}/>
                <Dialog.Button title="Abbrechen" onPress={() => {
                    setEditUserVisible(false)
                    setNewUserName(user.displayName || "");
                }}/>
            </Dialog.Actions>
        </Dialog>
    </>
}

const styles = StyleSheet.create({
    mainView: {},
    text: {
        color: Colors.white,
        fontSize: 18,
        width: "100%",
    },
    input: {
        color: Colors.white,
        height: 200,
    }
});