import React, {useState} from 'react';
import {Image, StyleSheet, Text} from 'react-native';
import {Formik} from 'formik';
import {createUserWithEmailAndPassword} from 'firebase/auth';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';

import {Button, FormErrorMessage, TextInput, View} from '../components';
import {auth, Colors, Images} from '../config';
import {useTogglePasswordVisibility} from '../hooks';
import {signupValidationSchema} from '../utils';
import {LSOViewWidget} from "../components/LSOViewWidget";
import {UserService} from "../providers/UserService";

export const SignupScreen = ({navigation}) => {
    const [errorState, setErrorState] = useState('');

    const {
        passwordVisibility,
        handlePasswordVisibility,
        rightIcon,
        handleConfirmPasswordVisibility,
        confirmPasswordIcon,
        confirmPasswordVisibility
    } = useTogglePasswordVisibility();

    const handleSignup = async (userName, email, password) => {

        createUserWithEmailAndPassword(auth, email, password)
            .then(user => {
                new UserService().updateUsername(user.user.uid, userName, email)
            })
            .catch(error =>
                setErrorState(error.message)
            );
    };

    return (
        <>
            <View isSafe style={styles.container}>
                <KeyboardAwareScrollView enableOnAndroid={true}>
                    {/* LogoContainer: consits app logo and screen title */}
                    <View style={styles.logoContainer} isSafe={true}>
                        <Image source={Images.logoAndBackground} style={styles.mainLogo}/>
                        <Text style={styles.screenTitle}>League of Stocks</Text>
                    </View>
                    {/* Formik Wrapper */}
                    <LSOViewWidget>
                        <Formik
                            initialValues={{
                                userName: '',
                                email: '',
                                password: '',
                                confirmPassword: ''
                            }}
                            validationSchema={signupValidationSchema}
                            onSubmit={values => handleSignup(values.userName, values.email, values.password)}
                        >
                            {({
                                  values,
                                  touched,
                                  errors,
                                  handleChange,
                                  handleSubmit,
                                  handleBlur
                              }) => (
                                <>
                                    {/* Input fields */}
                                    <TextInput
                                        name='userName'
                                        placeholder='Nutzernamen eingeben'
                                        autoCapitalize='none'
                                        autoFocus={true}
                                        value={values.userName}
                                        onChangeText={handleChange('userName')}
                                        onBlur={handleBlur('userName')} rightIcon={undefined}
                                        handlePasswordVisibility={undefined} leftIconName={undefined}/>
                                    <FormErrorMessage error={errors.userName} visible={touched.userName}/>
                                    <TextInput
                                        name='email'
                                        placeholder='E-Mail eingeben'
                                        autoCapitalize='none'
                                        keyboardType='email-address'
                                        textContentType='emailAddress'
                                        autoFocus={true}
                                        value={values.email}
                                        onChangeText={handleChange('email')}
                                        onBlur={handleBlur('email')} rightIcon={undefined}
                                        handlePasswordVisibility={undefined} leftIconName={undefined}/>
                                    <FormErrorMessage error={errors.email} visible={touched.email}/>
                                    <TextInput
                                        name='password'
                                        placeholder='Passwort eingeben'
                                        autoCapitalize='none'
                                        autoCorrect={false}
                                        secureTextEntry={passwordVisibility}
                                        textContentType='newPassword'
                                        rightIcon={rightIcon}
                                        handlePasswordVisibility={handlePasswordVisibility}
                                        value={values.password}
                                        onChangeText={handleChange('password')}
                                        onBlur={handleBlur('password')} leftIconName={undefined}/>
                                    <FormErrorMessage
                                        error={errors.password}
                                        visible={touched.password}
                                    />
                                    <TextInput
                                        name='confirmPassword'
                                        placeholder='Passwort eingeben'
                                        autoCapitalize='none'
                                        autoCorrect={false}
                                        secureTextEntry={confirmPasswordVisibility}
                                        textContentType='password'
                                        rightIcon={confirmPasswordIcon}
                                        handlePasswordVisibility={handleConfirmPasswordVisibility}
                                        value={values.confirmPassword}
                                        onChangeText={handleChange('confirmPassword')}
                                        onBlur={handleBlur('confirmPassword')} leftIconName={undefined}/>
                                    <FormErrorMessage
                                        error={errors.confirmPassword}
                                        visible={touched.confirmPassword}
                                    />
                                    {/* Display Screen Error Mesages */}
                                    {errorState !== '' ? (
                                        <FormErrorMessage error={errorState} visible={true}/>
                                    ) : null}
                                    {/* Signup button */}
                                    <Button style={styles.button} onPress={handleSubmit} title={undefined}>
                                        <Text style={styles.buttonText}>Signup</Text>
                                    </Button>
                                </>
                            )}
                        </Formik>
                    </LSOViewWidget>
                    {/* Button to navigate to Login screen */}
                    <Button
                        style={styles.borderlessButtonContainer}
                        borderless
                        title={'Already have an account?'}
                        onPress={() => navigation.navigate('Login')} children={undefined}/>
                </KeyboardAwareScrollView>
            </View>

            {/* App info footer */}
            <View style={styles.footer} isSafe={true}>
                <Image source={Images.footerBackground} style={styles.footerImage}/>
            </View>
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.black,
        paddingHorizontal: 12
    },
    footer: {
        alignItems: 'center'
    },
    footerImage: {
        width: "100%",
        height: 52,
        backgroundColor: Colors.black

    },
    logoContainer: {
        alignItems: 'center'
    },
    screenTitle: {
        fontSize: 32,
        fontWeight: '700',
        color: Colors.black,
        paddingTop: 20
    },
    button: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 8,
        backgroundColor: "#222425",
        padding: 10,
        borderRadius: 0
    },
    buttonText: {
        fontSize: 20,
        color: Colors.white,
        fontWeight: '700'
    },
    borderlessButtonContainer: {
        marginTop: 16,
        alignItems: 'center',
        justifyContent: 'center'
    },
    mainLogo: {
        marginTop: 20,
        width: "100%",
        height: 180,
        resizeMode: "stretch"
    }
});
