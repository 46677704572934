import React, {useContext} from "react";
import {ChallengeContext} from "../../../providers";
import {LSOViewWidget} from "../../../components/LSOViewWidget";
import {StyleSheet, Text, View} from "react-native";
import {Button, Divider, ListItem} from "@rneui/base";
import {Colors} from "../../../config";
import {useNavigation} from "@react-navigation/native";
import {UserNameComponent} from "./UserNameComponent";
import {ChallengeType} from "../model/ChallengeType";

export const ChallengeSelector = (props: {
    challengeList: ChallengeType[],
    title: string
}) => {
    const {setChallengeId} = useContext(ChallengeContext);
    const navigation = useNavigation();

    if (props.challengeList.length === 0) {
        return <></>
    }

    return <>
        <LSOViewWidget wideStyle>
            <Text style={styles.challengeText}>{props.title}</Text>

            <View style={{
                width: "100%",
                marginBottom: 20,
            }}>
                <Divider color={Colors.white} width={0.5} style={{marginBottom: 10}}/>
                {
                    props.challengeList.map((challenge, i) => (
                        <ListItem key={i} bottomDivider containerStyle={styles.listItemContentContainer}
                                  onPress={() => setChallengeId(challenge.id)}
                        >
                            <ListItem.Content>
                                <Button
                                    color={Colors.button}
                                    containerStyle={{width: "100%", borderRadius: 0}}
                                    buttonStyle={{borderRadius: 0}}
                                    style={{borderRadius: 0}}
                                    onPress={() => {
                                        setChallengeId(challenge.id)
                                        // @ts-ignore
                                        navigation.navigate(`Challenge`, {challengeId: challenge.id})
                                    }}
                                >
                                    <View>
                                        <Text style={{
                                            color: Colors.blackGrey,
                                            fontFamily: "Montserrat_700Bold",
                                            fontSize: 14,
                                            textAlign: "center"
                                        }}>{challenge.name}</Text>
                                        {challenge.creator &&
                                            <Text style={{
                                                color: Colors.blackGrey,
                                                fontFamily: "Montserrat_400Regular",
                                                fontSize: 8,
                                                textAlign: "center"
                                            }}>by <UserNameComponent user={challenge.creator}/></Text>
                                        }
                                    </View>
                                </Button>
                            </ListItem.Content>
                        </ListItem>
                    ))
                }
            </View>
        </LSOViewWidget>
    </>
}

const styles = StyleSheet.create({
    listItemContentContainer: {
        padding: 5,
        backgroundColor: "transparent",
        borderBottomWidth: 0
    },
    bandView: {
        backgroundColor: "#454545",
    },
    challengeText: {
        fontSize: 22,
        color: Colors.white,
        marginTop: 20,
        marginBottom: 10,
        fontFamily: "Montserrat_700Bold",
    },
});