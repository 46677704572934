import {addDoc, collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, setDoc, updateDoc} from "firebase/firestore";
import {db} from "../../../config";
import {StockSearchResponse} from "../../../providers/StockSearchResponse";
import {User} from "firebase/auth";
import {logEvent} from "firebase/analytics";
import {ChallengeUserType} from "../model/ChallengeUserType";
import {ChallengeType} from "../model/ChallengeType";
import {analytics} from "../../../config/firebase";

export const FB_CHALLENGE_ROOT = "stockpicking-challenge";

export class ChallengeService {

    public async fetchChallenges() {
        logEvent(analytics, "Challenges_Laden");
        const challengesRef = await getDocs(this.getChallengesCollection());

        return challengesRef.docs
            .map(doc => ({...doc.data() as ChallengeType, id: doc.id}));
    }

    public snapshotChallenges(cb: (challengList: ChallengeType[]) => void) {
        return onSnapshot(this.getChallengesCollection(), snapshot => {
            logEvent(analytics, "Challenges_Laden");
            cb(snapshot.docs
                .map(doc => ({...doc.data() as ChallengeType, id: doc.id})));
        });
    }

    public snapshotSelectedChallenge(challengeName: string, cb: (challenge) => void) {
        return onSnapshot(this.getChallengeDoc(challengeName), (docRef) => {
            if (docRef.exists()) {
                if (docRef.exists()) {
                    logEvent(analytics, "Challenge_Laden", {challengeName: challengeName});
                    let documentData = docRef.data() as ChallengeType;
                    documentData.id = docRef.id;
                    cb(documentData);
                }
            }
        });
    }

    public async fetchChallengeUser(challengeName: string, userId: string) {
        logEvent(analytics, "ChallengeNutzerdaten_Laden", {
            value: challengeName,
            challengeName: challengeName,
            userId: userId
        });
        const userDocRef = await getDoc(this.getChallengeUserDoc(challengeName, userId));
        if (userDocRef.exists()) {
            return userDocRef.data() as ChallengeUserType;
        }
    }

    public async updateUserConfirmed(challengeId: string, user: User) {
        logEvent(analytics, "NutzerConfirmed_Update", {
            value: challengeId,
            challengeId: challengeId,
            userId: user.uid
        });
        const userDocRef = await getDoc(this.getChallengeUserDoc(challengeId, user.uid));

        if (userDocRef.exists()) {
            const userDoc = userDocRef.data();
            userDoc.hasConfirmed = true;
            userDoc.updated = new Date().toISOString();
            await updateDoc(userDocRef.ref, userDoc);
        } else {
            await setDoc(this.getChallengeUserDoc(challengeId, user.uid), {
                displayName: user.displayName,
                email: user.email,
                id: user.uid,
                hasConfirmed: true,
                createdDate: new Date().toISOString()
            });
        }
    }

    public async updateUser(challengeId: string, userId: string, data: { [p: string]: any }) {
        logEvent(analytics, "ChallengeNutzer_Update", {
            value: challengeId,
            challengeId: challengeId,
            userId: userId
        });
        const userDocRef = await getDoc(this.getChallengeUserDoc(challengeId, userId));

        try {
            await updateDoc(userDocRef.ref, data);
        } catch (e) {
            console.log(e)
        }
    }

    public async updateUserNrMessages(challengeId: string, userId: string, nrReadMessages: number) {
        logEvent(analytics, "NutzerNrMessages_Update", {
            value: challengeId,
            challengeId: challengeId,
            userId: userId
        });
        const userDocRef = await getDoc(this.getChallengeUserDoc(challengeId, userId));

        try {
            await updateDoc(userDocRef.ref, {
                nrReadMessages
            });
        } catch (e) {
            console.log(e)
        }
    }

    public async updateUserStock(challengeId: string, user: User, selectedStock: StockSearchResponse) {
        logEvent(analytics, "NutzerWertpapier_Update", {
            value: challengeId,
            challengeId: challengeId,
            userId: user.uid
        });
        const userDocRef = await getDoc(this.getChallengeUserDoc(challengeId, user.uid));

        if (userDocRef.exists()) {
            const userDoc = userDocRef.data();
            userDoc.selectedStock = selectedStock;
            userDoc.updated = new Date().toISOString();
            await updateDoc(userDocRef.ref, userDoc);
        } else {
            await setDoc(this.getChallengeUserDoc(challengeId, user.uid), {
                displayName: user.displayName,
                email: user.email,
                id: user.uid,
                selectedStock: selectedStock,
                createdDate: new Date().toISOString()
            });
        }
    }

    public snapshotChallengeChatMessages(challengeId: string, cb: (messages) => void) {
        return onSnapshot(this.getChallengeChatCollection(challengeId), snapshot => {
            logEvent(analytics, "ChallengeChatMessages_Laden", {value: challengeId, challengeId: challengeId});
            cb(snapshot.docs.map(doc => ({...doc.data(), id: doc.id})));
        });
    }

    public async createChallenge(challenge: ChallengeType) {
        logEvent(analytics, "Challenge_Erstellen", {value: challenge.id, challengeId: challenge.id});
        const docRef = await addDoc(this.getChallengesCollection(), challenge);
        challenge.id = docRef.id
        return challenge
    }

    public createChallengeChatMessage(challengeId: string, message) {
        logEvent(analytics, "ChallengeChatMessage_Erstellen", {value: challengeId, challengeId: challengeId});
        return addDoc(this.getChallengeChatCollection(challengeId), message);
    }

    public async removeChallenge(challengeId: string) {
        logEvent(analytics, "Challenge_Loeschen", {value: challengeId, challengeId: challengeId});
        return deleteDoc(this.getChallengeDoc(challengeId));
    }

    private getChallengesCollection() {
        return collection(db, FB_CHALLENGE_ROOT);
    }

    private getChallengeChatCollection(challengeId: string) {
        return collection(db, FB_CHALLENGE_ROOT, challengeId, "chat");
    }

    private getChallengeDoc(challengeName: string) {
        return doc(db, FB_CHALLENGE_ROOT, challengeName);
    }

    private getChallengeUserDoc(challengeName: string, userId: string) {
        return doc(db, FB_CHALLENGE_ROOT, challengeName, "user", userId);
    }
}