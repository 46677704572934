import {initializeApp} from 'firebase/app';
import {initializeAuth} from 'firebase/auth';
import {
    getAnalytics,
    setAnalyticsCollectionEnabled,
    setDefaultEventParameters,
    setUserProperties
} from "firebase/analytics";

import {getReactNativePersistence} from "firebase/auth/react-native"
import AsyncStorage from "@react-native-async-storage/async-storage"
import {initializeFirestore} from "firebase/firestore";
import {Platform} from "react-native";

// add firebase config
const firebaseConfig = {
    apiKey: "AIzaSyDf9q-c9uxMJiVK-byEVqHJR51OIJcCL70",
    authDomain: "pinc-business.firebaseapp.com",
    projectId: "pinc-business",
    storageBucket: "pinc-business.appspot.com",
    messagingSenderId: "579806548059",
    appId: "1:579806548059:web:aba4853933e28108abaf7e",
    measurementId: "G-BQ10NY4GZ7"
};

// initialize firebase
setDefaultEventParameters({app_version: `LoS-${Platform.OS}`})
const app = initializeApp(firebaseConfig);

let analytics = getAnalytics(app);
setAnalyticsCollectionEnabled(analytics, true);

// initialize auth
const auth = initializeAuth(app, {
    persistence: getReactNativePersistence(AsyncStorage),
});

const db = initializeFirestore(app, {experimentalAutoDetectLongPolling: true})

export {auth, app, db, analytics, setUserProperties};
