import React, {useContext} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {ChallengeFooter} from "./components/ChallengeFooter";
import {KeyboardAwareScrollView} from "react-native-keyboard-aware-scroll-view";
import {ChallengeSelector} from "./components/ChallengeSelectorComponent";
import {ChallengeContext} from "../../providers";
import {MainChallengeLogo} from "./components/MainChallengeLogo";


export const ChallengeListScreen = () => {
    const {challengeList} = useContext(ChallengeContext);

    return (
        <View style={styles.container}>
            <ScrollView>
                <View style={styles.mainView}>

                    <KeyboardAwareScrollView enableOnAndroid={true}>
                        <MainChallengeLogo text={"Challenge-Mode"}/>

                        <ChallengeSelector challengeList={challengeList.filter(challenge => !challenge.isFinished)}
                                           title="Aktuelle Challenges"/>
                        <ChallengeSelector challengeList={challengeList.filter(challenge => challenge.isFinished)}
                                           title="Abgelaufene Challenges"/>

                    </KeyboardAwareScrollView>
                </View>
            </ScrollView>
            <ChallengeFooter/>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#000000"
    },
    footerImage: {
        flex: 1,
    },
    mainView: {},
});
