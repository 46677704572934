/** get standard currency format for EUR */
const CONSTANT_FORMAT_CURRENCY = {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
};

/** get locale for germany */
export function getLocaleString() {
    return 'de-DE';
}

/**
 * get string format for a specific currency
 * @param currency currency to be used for formatting
 */
function getFormatCurrency(currency: string): Intl.NumberFormatOptions {
    return {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: 2
    }
}

/**
 * convert a number which is considered a price to a string
 * @param money the number that is converted to a string
 * @param currency optional: the currency to be displayed
 * @return converted string of the input or "" if money is undefined
 */
export function currency2string(money: number | undefined, currency: string | undefined = undefined) {
    if (currency) {
        return money?.toLocaleString(getLocaleString(), getFormatCurrency(currency)) || "";
    }
    return money?.toLocaleString(getLocaleString(), CONSTANT_FORMAT_CURRENCY) || "";
}

export function percent2string(num: number | undefined) {
    return num?.toFixed(2) + " %" || "";
}
