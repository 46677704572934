import React, {useEffect, useState} from "react";
import {createElement, Platform} from "react-native";
import moment from "moment/moment";
import RNDateTimePicker from "@react-native-community/datetimepicker";

export const WebDatePicker = (props: { date: Date, onChange: (date: Date) => void }) => {

    let [date, setDate] = useState<Date>(props.date);

    useEffect(() => {
        setDate(props.date)
    }, [props.date])

    return createElement('input', {
        type: 'datetime-local',
        value: moment(date).utc(true).toISOString().slice(0, 16),
        onChange: (event) => {
            let changedDate = new Date(event.target.value);
            props.onChange(changedDate)
        },
        style: {height: 30, padding: 5, border: "2px solid #677788", borderRadius: 5, width: 250}
    })
}

export const DatePicker = (props: { date: Date, onChange: (date: Date) => void }) => {

    if (Platform.OS === "web") {
        return <WebDatePicker onChange={props.onChange} date={props.date}/>
    } else if (Platform.OS === "ios") {
        return <RNDateTimePicker mode="datetime" value={props.date}/>
    }
    return <>
        <RNDateTimePicker mode="date" value={props.date}/>
        <RNDateTimePicker mode="time" value={props.date}/>
    </>
}