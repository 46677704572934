import {StockPriceResponse} from "../../../providers/StockPriceResponse";
import {auth} from "../../../config";
import axios from "axios";
import {StockSearchResponse} from "../../../providers/StockSearchResponse";
import {Memoizee} from "../../../utils/MemoizeeMethodDecorator";
import {analytics} from "../../../config/firebase";
import {logEvent} from "firebase/analytics";

export const MOMENT_BACKEND_DATE_FORMAT = "YYYY-MM-DD";

const SERVICE_HOST = `https://app.pinc.business`;

export interface HistoricalStockPriceResponse {
    date: string;
    open: string;
    high: string;
    low: string;
    close: string;
    adjusted_close: string;
    volume: string;
}

export class StockService {

    @Memoizee({promise: true})
    public async historicalPrices(symbol: string, from: string, to: string, period: string): Promise<HistoricalStockPriceResponse[]> {
        logEvent(analytics, "LadeHistorischeDaten", {value: symbol});
        return axios.get<HistoricalStockPriceResponse[]>(`${SERVICE_HOST}/api/stocks/historical/${symbol}`,
            {
                ...await this.getAxiosConfig(), params: {
                    from: from,
                    to: to,
                    period: period
                }
            }).then(response => response.data);
    }

    @Memoizee({
        promise: true,
        maxAge: 5 * 60 * 1000
    })
    public async fetchStockPrice(symbol: string): Promise<StockPriceResponse> {
        logEvent(analytics, "LadeWertpapierPreis", {value: symbol});
        return await axios.get<StockPriceResponse>(
            `${SERVICE_HOST}/api/stocks/price/${symbol}`,
            await this.getAxiosConfig()
        ).then(response => response.data);
    }

    @Memoizee({
        promise: true,
        maxAge: 5 * 60 * 1000
    })
    public async searchForStocks2(searchString) {
        logEvent(analytics, "NachWertpapier_Suche2", {value: searchString});
        return axios.get<StockSearchResponse[]>(
            `${SERVICE_HOST}/api/stocks/search2/${searchString}`,
            await this.getAxiosConfig()
        ).then(response => response.data)
            // @ts-ignore
            .then(stocks => stocks.map(stock => ({...stock, ISIN: stock.Isin})));
    }

    @Memoizee({
        promise: true,
        maxAge: 5 * 60 * 1000
    })
    public async searchForStocks(searchString) {
        logEvent(analytics, "Wertpapier_Suchen", {value: searchString});
        return axios.get<StockSearchResponse[]>(
            `${SERVICE_HOST}/api/stocks/search/${searchString}`,
            await this.getAxiosConfig()
        ).then(response => response.data)
    }


    public async sendMail(mailType: string, subject: string, htmlContent) {
        logEvent(analytics, "Mail_Send", {value: mailType, mailType: mailType});
        return axios.post("${SERVICE_HOST}/api/feedback/send",
            {
                type: mailType,
                subject: subject,
                htmlContent: htmlContent
            },
            await this.getAxiosConfig()
        )
    }

    private async getAxiosConfig() {
        const accessToken = await auth.currentUser?.getIdToken();
        return {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        }
    }

    public async getAiHint(): Promise<{ name: string, isin: string, reason: string }> {
        logEvent(analytics, "LadeKIHilfe");
        return await axios.get(`${SERVICE_HOST}/api/bts/stockAdvise`, await this.getAxiosConfig())
            .then(response => JSON.parse(response.data));
    }
}