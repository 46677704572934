import React, {useContext, useEffect, useState} from 'react';
import {NavigationContainer} from '@react-navigation/native';
import {onAuthStateChanged} from 'firebase/auth';

import {AuthStack} from './AuthStack';
import {ChallengeStack} from './ChallengeStack';
import {AuthenticatedUserContext} from '../providers';
import {auth} from '../config';
import {LinearProgress} from "@rneui/base";
import {analytics} from "../config/firebase";
import {logEvent, setUserId, setUserProperties} from "firebase/analytics";
import {Linking} from "react-native";

const linking = {
    prefixes: [
        "http://localhost:19006/",
        "https://stock-picking-game.web.app/"
    ],
    config: {
        // see: https://reactnavigation.org/docs/configuring-links
        screens: {
            Challenge: 'Challenge/:challengeId',
            ChallengeInfo: 'Challenge/:challengeId/info',
            ChallengeChat: 'Challenge/:challengeId/chat',
            ChallengeCreate: 'ChallengeNeu'
        }
    },
};

export const RootNavigator = () => {
    const {user, setUser} = useContext(AuthenticatedUserContext);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        return onAuthStateChanged(
            auth,
            authenticatedUser => {
                if (authenticatedUser) {
                    setUser(authenticatedUser)
                    setUserId(analytics, authenticatedUser.uid);
                } else {
                    setUser(null)
                }
                setIsLoading(false);
            }
        );
    }, [user]);

    useEffect(() => {
        // check if the initial url has a parameter called me that contains a marketing event value
        Linking.getInitialURL().then((url) => {
            if (url) {
                const urlParams = new URLSearchParams(url);
                const campaignId = urlParams.get('utm_id');
                if (campaignId) {
                    setUserProperties(analytics, {campaignId: campaignId});
                    logEvent(analytics, 'MarketingEvent', {value: campaignId, campaignId: campaignId});
                }
            }
        });
    }, []);

    if (isLoading) {
        return <LinearProgress/>;
    }

    return (
        <NavigationContainer linking={linking}>
            {user ? <ChallengeStack/> : <AuthStack/>}
        </NavigationContainer>
    );
};
