import {HasPerformance} from "../model/HasPerformance";

function sortPerformanceFn(negativeWins = false): (a: HasPerformance, b: HasPerformance) => number {
    return (a: HasPerformance, b: HasPerformance) => {
        if (a.performance !== undefined && b.performance !== undefined) {
            if (negativeWins) {
                return a.performance - b.performance;
            }
            return b.performance - a.performance;
        }
        return 0;
    };
}


export function rankedList<T extends HasPerformance>(users: T[], negativeWins = false): { user: T, rank: number }[] {
    let rank = 1;
    let iCounter = 1;
    let lastUser: T = undefined;
    let rUserList = []
    for (const user of users.sort(sortPerformanceFn(negativeWins))) {
        if (lastUser) {
            if (lastUser.performance !== user.performance) {
                rank = iCounter;
            }
        }
        rUserList.push({user, rank});
        lastUser = user;
        iCounter++;
    }
    return rUserList
}


// remove all properties from object that are undefined
export function removeUndefinedProperties(obj) {
    let newObj = {};
    for (let key in obj) {
        if (obj[key] !== undefined) {
            newObj[key] = obj[key];
        }
    }
    return newObj;
}


export function getExchangeName(exchangeCode?: string) {
    if (exchangeCode) {
        if (exchangeCode === "XETRA") {
            return "XETRA"
        } else if (exchangeCode === "US") {
            return "New York"
        } else if (exchangeCode === "LSE") {
            return "London"
        } else {
            return exchangeCode
        }
    }
    return ""
}