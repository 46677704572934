import * as Yup from 'yup';

export const loginValidationSchema = Yup.object().shape({
    email: Yup.string().required().email().label('Email'),
    password: Yup.string().required().min(6).label('Password')
});

export const signupValidationSchema = Yup.object().shape({
    userName: Yup.string().required().min(1).label('Nutzername'),
    email: Yup.string().required().email().label('Email'),
    password: Yup.string().required().min(6).label('Password'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Beide Passwörter müssen übereinstimmen.')
        .required('Bitte Passwort bestätigen')
});

export const passwordResetSchema = Yup.object().shape({
    email: Yup.string()
        .required('Bitte geben Sie eine gültige E-Mail ein')
        .label('Email')
        .email('Gültige E-Mail eingeben')
});
