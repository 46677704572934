import {ApolloClient, ApolloProvider, createHttpLink, gql, InMemoryCache, useQuery} from "@apollo/client";
import {LinearProgress} from "@rneui/base";
import {ScrollView, Text} from "react-native";
import {HtmlView} from "../components/HtmlView";
import * as React from "react";

interface StaticFooterPage {
    staticFooterPage: {
        content: {
            html: string;
        }
    };
}

export const StaticPage = (props: { pageName: string }) => {

    const StaticFooterPageQuery = gql`
        query getStaticPage($pageName: String) {
          staticFooterPage(where: {pageName: $pageName}) {
            content {
              html
            }
          }
        }
`;

    const {
        loading,
        error,
        data
    } = useQuery<StaticFooterPage>(StaticFooterPageQuery, {variables: {pageName: props.pageName}})

    if (loading) {
        return <>
            <LinearProgress/>
        </>;
    }

    if (error || !data?.staticFooterPage?.content?.html) {
        return <>
            <Text>Seite nicht gefunden</Text>
        </>;
    }
    return <HtmlView html={data?.staticFooterPage?.content?.html}/>
}

export const StaticPageScreen = ({route}) => {

    const client = new ApolloClient({
        link: createHttpLink({uri: 'https://api-eu-central-1.graphcms.com/v2/ckgy2suit8kxa01z46uond839/master'}),
        cache: new InMemoryCache()
    });

    return <>
        <ApolloProvider client={client}>
            <ScrollView>
                <StaticPage pageName={route.name.toLowerCase()}/>
            </ScrollView>
        </ApolloProvider>
    </>
}