export function time2string(minutes: number, prefix = ""): string {
    if (minutes === undefined || minutes <= 1) {
        return "gerade eben"
    }

    let days = Math.floor(minutes / 1440);
    let hours = Math.floor((minutes - days * 1440) / 60);
    let minutes2 = minutes - days * 1440 - hours * 60;
    let result = "";
    if (days > 0) {
        result += days + "d ";
    }
    if (hours > 0) {
        result += hours + "h ";
    }
    if (minutes2 > 0) {
        result += minutes2 + "min";
    }
    return prefix + result;
}