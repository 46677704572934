import * as React from 'react';
import {useContext} from 'react';
import {createStackNavigator} from '@react-navigation/stack';

import {ChallengeScreen} from '../screens';
import {Colors, Images} from "../config";
import {Image, StyleSheet} from "react-native";
import {MainMenu} from "../components/MainMenu";
import {TouchableOpacity} from "react-native-gesture-handler";
import {StaticPageScreen} from "../screens/StaticPageScreen";
import {ChallengeContext, ChallengeProvider} from "../providers";
import {FeedbackScreen} from "../screens/FeedbackScreen";
import {ChallengeListScreen} from "../screens/challenge/ChallengeListScreen";
import {useNavigation} from "@react-navigation/native";
import {ChallengeCreateScreen} from "../screens/challenge/ChallengeCreateScreen";
import {ChallengeChatScreen} from "../screens/challenge/ChallengeChatScreen";
import {UserScreen} from "../screens/UserScreen";
import {ChallengeConfirmScreen} from "../screens/challenge/ChallengeConfirmScreen";

const Stack = createStackNavigator();


export const HeaderMainLogo = () => {
    const {setChallengeId, setSelectedChallenge} = useContext(ChallengeContext);
    const navigation = useNavigation();

    return <TouchableOpacity onPress={() => {
        setChallengeId()
        setSelectedChallenge()
        // @ts-ignore
        navigation.navigate("Challenges");
    }}>
        <Image source={Images.logo} style={styles.logo}/>
    </TouchableOpacity>
}

export const ChallengeStack = () => {

    const screenOptions = () => ({
        cardStyle: styles.cardStyle,
        title: "Testapp Börsenkreis",
        headerStyle: styles.headerStyle,
        headerTitleStyle: styles.headerTitle,
        headerLeft: () => <HeaderMainLogo/>,
        headerRight: () => <MainMenu/>
    })

    return (
        <ChallengeProvider>
            <Stack.Navigator initialRouteName='Challenges'>
                <Stack.Screen name='Challenges' component={ChallengeListScreen} options={screenOptions}/>
                <Stack.Screen name='Challenge' component={ChallengeScreen} options={screenOptions}/>
                <Stack.Screen name='ChallengeInfo' component={ChallengeConfirmScreen} options={screenOptions}/>
                <Stack.Screen name='ChallengeChat' component={ChallengeChatScreen} options={screenOptions}/>
                <Stack.Screen name='ChallengeCreate' component={ChallengeCreateScreen} options={screenOptions}/>
                <Stack.Screen name='Feedback' component={FeedbackScreen} options={screenOptions}/>
                <Stack.Screen name='Contact' component={StaticPageScreen} options={screenOptions}/>
                <Stack.Screen name='User' component={UserScreen} options={screenOptions}/>
            </Stack.Navigator>
        </ChallengeProvider>
    );
};

const styles = StyleSheet.create({
    headerStyle: {
        backgroundColor: "#000000",
        borderBottomWidth: 0,
        height: 80,
    },
    cardStyle: {
        backgroundColor: "#000000",
    },
    logo: {
        marginLeft: 20,
        width: 64,
        height: 64
    },
    headerTitle: {
        fontSize: 18,
        color: Colors.white,
        paddingLeft: 20,
        paddingTop: 5,
        fontFamily: "Montserrat_500Medium"
    }
});