import React, {useContext} from "react";
import {AuthenticatedUserContext, ChallengeContext} from "../../../providers";
import {StockSearchInput} from "./StockSearchInput";
import {SelectedStockDisplayComponent} from "./SelectedStockDisplayComponent";
import {Image, StyleSheet, Text, View} from "react-native";
import {Colors, Images} from "../../../config";
import {LSOViewWidget} from "../../../components/LSOViewWidget";
import {ChallengeFinishedComponent} from "./ChallengeFinishedComponent";
import {ChallengeTimeComponent} from "./ChallengeTimeComponent";
import {KeyboardAwareScrollView} from "react-native-keyboard-aware-scroll-view";
import {ChallengeActiveComponent} from "./ChallengeActiveComponent";
import {ChallengeConfirmComponent} from "./ChallengeConfirmComponent";
import {LinearProgress} from "@rneui/base";


export const ChallengeComponent = () => {

    const {user} = useContext(AuthenticatedUserContext);
    const {selectedChallenge} = useContext(ChallengeContext);

    if (!user || !selectedChallenge) {
        return <></>
    }

    return <>
        <View style={styles.mainView}>

            <KeyboardAwareScrollView enableOnAndroid={true}>
                <LSOViewWidget text={selectedChallenge.creator === undefined ? selectedChallenge.name : <View>
                    <Text style={{
                        color: Colors.white,
                        textAlign: "center",
                        fontSize: 18,
                        fontFamily: "Montserrat_800ExtraBold"
                    }}>{selectedChallenge.name}</Text>
                    <Text style={{color: Colors.white, textAlign: "center", fontFamily: "Montserrat_400Regular"}}>
                        by {selectedChallenge.creator.displayName || selectedChallenge.creator.email}
                    </Text>
                </View>}>
                    <Image source={Images.logoChallenge} style={styles.mainLogo}/>
                </LSOViewWidget>
                <ChallengeStateComponent/>
            </KeyboardAwareScrollView>
        </View>
    </>
}

const ChallengeStateComponent = () => {
    const {selectedStock, selectedChallenge, challengeConfirmed} = useContext(ChallengeContext);

    if (!selectedChallenge || !challengeConfirmed) {
        if (!selectedChallenge) {
            return <>
                <LinearProgress/>
            </>
        } else {
            return <ChallengeConfirmComponent/>
        }
    } else {
        if (selectedChallenge.isStarted) {
            if (selectedChallenge.isFinished) {
                return <ChallengeFinishedComponent/>
            } else {
                return <ChallengeActiveComponent/>
            }
        } else {
            if (selectedStock) {
                return <>
                    <ChallengeTimeComponent text={"Challenge startet in:"} date={selectedChallenge.start}/>
                    <ChallengeTimeComponent text={"Challenge endet in:"} date={selectedChallenge.end}/>
                    <SelectedStockDisplayComponent/>
                </>
            }
            return <>
                <ChallengeTimeComponent text={"Challenge startet in:"} date={selectedChallenge.start}/>
                <ChallengeTimeComponent text={"Challenge endet in:"} date={selectedChallenge.end}/>
                <StockSearchInput/>
            </>
        }
    }
}

const styles = StyleSheet.create({
    mainView: {
    },
    mainLogo: {
        marginTop: 20,
        width: 180,
        height: 180
    }
});