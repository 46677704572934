import * as React from "react";
import {useContext, useState} from "react";
import {AuthenticatedUserContext} from "../providers";
import {signOut} from "firebase/auth";
import {auth} from "../config";
import {TouchableOpacity} from "react-native-gesture-handler";
import Ionicons from "@expo/vector-icons/Ionicons";
import {BottomSheet, ListItem} from "@rneui/base";
import {AntDesign} from "@expo/vector-icons";
import {StyleSheet} from "react-native";
import {useNavigation} from "@react-navigation/native";

export function MainMenu() {

    const [isVisible, setIsVisible] = useState(false);
    const {user, userName} = useContext(AuthenticatedUserContext);
    const navigation = useNavigation();

    const handleLogout = () => {
        signOut(auth).catch(error => console.log('Error logging out: ', error));
    };

    return <>
        <TouchableOpacity onPress={() => setIsVisible(true)}>
            <Ionicons name="md-menu" size={32} color="grey" style={styles.menuLogo}/>
        </TouchableOpacity>

        <BottomSheet modalProps={{}} isVisible={isVisible} onBackdropPress={() => setIsVisible(false)}>

            <ListItem key="challenge"
                      onPress={() => {
                          setIsVisible(false)
                          // @ts-ignore
                          navigation.navigate('Challenges')
                      }}>
                <ListItem.Content style={{flexDirection: "row"}}>
                    <ListItem.Title style={{color: "black"}}>Challenges</ListItem.Title>
                </ListItem.Content>
            </ListItem>

            <ListItem key="feedback"
                      onPress={() => {
                          setIsVisible(false)
                          // @ts-ignore
                          navigation.navigate('Feedback')
                      }}>
                <ListItem.Content style={{flexDirection: "row"}}>
                    <ListItem.Title style={{color: "black"}}>Feedback</ListItem.Title>
                </ListItem.Content>
            </ListItem>

            <ListItem key="contact"
                      onPress={() => {
                          setIsVisible(false)
                          // @ts-ignore
                          navigation.navigate('Contact')
                      }}>
                <ListItem.Content style={{flexDirection: "row"}}>
                    <ListItem.Title style={{color: "black"}}>Impressum</ListItem.Title>
                </ListItem.Content>
            </ListItem>

            <ListItem key="user"
                      onPress={() => {
                          setIsVisible(false)
                          // @ts-ignore
                          navigation.navigate('User')
                      }}>
                <ListItem.Content style={{flexDirection: "row"}}>
                    <AntDesign name="user" size={16} color="grey"/>
                    <ListItem.Title
                        style={{color: "black"}}>{user ? userName : "user ist nicht eingeloggt"}</ListItem.Title>
                </ListItem.Content>
            </ListItem>

            <ListItem key="logout"
                      containerStyle={{backgroundColor: "red"}}
                      onPress={handleLogout}>
                <ListItem.Content>
                    <ListItem.Title style={{color: "white"}}>Ausloggen</ListItem.Title>
                </ListItem.Content>
            </ListItem>
        </BottomSheet></>;
}

const styles = StyleSheet.create({
    menuLogo: {
        marginRight: 20,
    },
});